.deal-engine .deal-banner {
  width: 100%;
  height: 100vh;
  background: #132233 url(@/assets/banner_2.jpg) no-repeat;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.deal-engine .deal-banner span {
  font-size: 1.25vw;
  line-height: 2.5vw;
  font-weight: 400;
  text-align: center;
}
.deal-engine .deal-banner .deal-baner-title {
  font-size: 3.75vw;
  margin-bottom: 4.0625vw;
}
.deal-engine .deal-intro {
  display: flex;
  box-sizing: border-box;
  padding: 7.8125vw;
  width: 100%;
  height: 46.875vw;
  background: #132233 url(@/assets/bg_2ping.png) no-repeat;
  background-size: 100% 100%;
}
.deal-engine .deal-intro .intro-banner {
  height: 31.25vw;
  display: flex;
  margin-right: 5vw;
}
.deal-engine .deal-intro .intro-banner .intro-banner-left {
  margin-right: 1.041667vw;
}
.deal-engine .deal-intro .intro-banner .intro-banner-left img {
  width: 15.625vw;
  height: 100%;
}
.deal-engine .deal-intro .intro-banner .intro-banner-right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.deal-engine .deal-intro .intro-banner .intro-banner-right img {
  width: 26.041667vw;
  height: 15.104167vw;
}
.deal-engine .deal-intro .intro-info {
  display: flex;
  flex-direction: column;
}
.deal-engine .deal-intro .intro-info .first-title {
  font-size: 1.5625vw;
  margin-top: 4.166667vw;
  font-weight: 500;
}
.deal-engine .deal-intro .intro-info .sub-title {
  font-size: 1.041667vw;
  font-weight: 400;
  margin-top: 2.083333vw;
  margin-bottom: 1.041667vw;
}
.deal-engine .deal-intro .intro-info .info-desc {
  font-size: 0.833333vw;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: #C4C6CC;
}
.deal-engine .deal-modal {
  width: 100%;
  height: 70.833333vw;
  background: #132233 url(@/assets/bg_3ping.jpg) no-repeat;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
}
.deal-engine .deal-modal .deal-modal-title {
  font-size: 2.083333vw;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 4.583333vw;
  text-align: center;
  margin-top: 6.510417vw;
  margin-bottom: 7.03125vw;
}
.deal-engine .deal-modal .deal-modal-list {
  position: relative;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  padding: 0 6.25vw;
}
.deal-engine .deal-modal .deal-modal-list .deal-modal-item {
  text-align: center;
}
.deal-engine .deal-modal .deal-modal-list .deal-modal-item span {
  text-align: center;
  display: inline-block;
}
.deal-engine .deal-modal .deal-modal-list .deal-modal-item span:nth-child(1) {
  width: 10.416667vw;
  line-height: 3.125vw;
  font-size: 1.5625vw;
  border: 0.104167vw solid #3CA1FF;
  margin-bottom: 1.5625vw;
}
.deal-engine .deal-modal .deal-modal-list .deal-modal-item span:nth-child(2) {
  font-size: 0.833333vw;
  line-height: 1.875vw;
}
.deal-engine .deal-modal .deal-modal-list .deal-modal-item:nth-child(2) {
  margin-top: 6.770833vw;
}
.deal-engine .deal-modal .deal-modal-list .deal-modal-item:nth-child(3) {
  margin-top: 3.645833vw;
}
.deal-engine .deal-modal .deal-modal-list .deal-modal-item:nth-child(4) {
  margin-top: 0.625vw;
}
.deal-engine .deal-modal .deal-modal-list .deal-modal-item:nth-child(5) {
  margin-top: 3.75vw;
}
.deal-engine .deal-modal .deal-modal-list .deal-modal-item:hover {
  -webkit-transform: translate(0, -20%);
  transform: translate(0, -20%);
}
