.mobile-nav-list {
  z-index: 999;
  position: fixed;
  display: flex;
  flex-direction: column;
  bottom: 18.666667vw;
  right: 4vw;
}
.mobile-nav-list div {
  width: 12vw;
  height: 12vw;
  background-color: #fff;
  margin-top: 3.6vw;
  border-radius: 50%;
  cursor: pointer;
}
.mobile-nav-list div img {
  width: 5.6vw;
  height: 5.6vw;
}
.mobile-nav-list .nav-item-1 {
  display: flex;
  justify-content: center;
  align-items: center;
}
.mobile-nav-list .nav-item-2 {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mobile-nav-list .nav-item-2 .qp {
  position: absolute;
  bottom: -2.666667vw;
  left: -29.333333vw;
  width: 26.666667vw;
  height: 29.333333vw;
  display: none;
}
.mobile-nav-list .nav-item-2 .pic {
  position: absolute;
  bottom: 13.333333vw;
  left: -28vw;
  display: none;
}
.mobile-nav-list .nav-item-2 .pic img {
  width: 22.133333vw;
  height: 22.133333vw;
}
.mobile-nav-list .nav-item-2 .pic p {
  font-size: 1.866667vw;
  color: #666666;
  width: 66.666667vw;
  position: relative;
  top: 0px;
  left: 0.533333vw;
}
.mobile-nav-list .nav-item-3 {
  display: flex;
  justify-content: center;
  align-items: center;
}
.mobile-nav-list .nav-item-3 .qp {
  position: absolute;
  bottom: -2.666667vw;
  left: -29.333333vw;
  width: 26.666667vw;
  height: 29.333333vw;
  display: none;
}
.mobile-nav-list .nav-item-3 .pic {
  position: absolute;
  bottom: 13.333333vw;
  left: -28vw;
  display: none;
}
.mobile-nav-list .nav-item-3 .pic img {
  width: 22.133333vw;
  height: 22.133333vw;
}
.mobile-nav-list .nav-item-3 .pic p {
  font-size: 1.866667vw;
  color: #666666;
  width: 66.666667vw;
  position: relative;
  top: 0px;
  left: 0.533333vw;
}
