.detail-item-2 .bg-1 .text {
  position: absolute;
  top: 19.791667vw;
  left: 36.458333vw;
}
.detail-item-3 .bg-1 .text {
  position: absolute;
  top: 20.833333vw;
  left: 38.541667vw;
}
