ul,
li {
  padding: 0;
  margin: 0;
  list-style: none;
}
.hiddenInput {
  opacity: 0;
}
.mobile-ecology {
  height: auto;
  width: 100%;
}
.mobile-ecology .banner {
  position: relative;
  height: 100vh;
  width: 100%;
  background: #132233;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mobile-ecology .banner img {
  position: absolute;
  width: 100%;
  height: 100%;
}
.mobile-ecology .banner .banner-title {
  font-size: 7.333333vw;
  font-family: PingFang SC;
  font-weight: 500;
  color: #FFFFFF;
}
.mobile-ecology .banner .data-list {
  width: 100%;
  height: 13.333333vw;
  background: #060D1A;
  opacity: 0.5;
  position: absolute;
  bottom: 0;
}
.mobile-ecology .banner .data-list .ant-tabs-nav {
  border-bottom: 0!important;
  margin: 0;
}
.mobile-ecology .banner .data-list .ant-tabs-nav .ant-tabs-tab {
  padding: 0;
  margin: 0;
}
.mobile-ecology .banner .data-list .ant-tabs-nav .ant-tabs-tab-active div {
  color: #FFFFFF;
  opacity: 1;
}
.mobile-ecology .banner .data-list .ant-tabs-nav .ant-tabs-ink-bar {
  background-color: #fff;
}
.mobile-ecology .banner .data-list .ant-tabs-nav::before {
  border-bottom: 0!important;
}
.mobile-ecology .banner .data-list .banner-tabs {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 23.466667vw;
  height: 13.866667vw;
}
.mobile-ecology .banner .data-list .banner-tabs div {
  text-align: center;
  font-size: 3.733333vw;
  font-family: PingFang SC;
  color: #FFFFFF;
  opacity: 0.6;
}
.mobile-ecology .introduction {
  width: 100%;
  padding: 7.733333vw 4vw;
  background: #132233 url('@/assets/ecologybg2.png') no-repeat 100% 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.mobile-ecology .introduction .title {
  font-size: 4.8vw;
  font-family: PingFang SC;
  font-weight: 500;
  color: #FFFFFF;
  margin-bottom: 8vw;
}
.mobile-ecology .introduction img {
  width: 100%;
  height: 53.333333vw;
}
.mobile-ecology .introduction .graphy {
  width: 100%;
  padding: 8vw 4vw;
  background: #081525;
  opacity: 0.7;
}
.mobile-ecology .introduction .graphy span {
  display: block;
  text-indent: 2em;
  font-size: 3.2vw;
  font-family: PingFang SC;
  color: #FFFFFF;
  line-height: 6.133333vw;
  text-align: justify;
}
.mobile-ecology #action {
  width: 100%;
  padding: 8vw 0;
  background: #132233 url('@/assets/ecologybg3.png') no-repeat 100% 100%;
}
.mobile-ecology #action .title {
  display: block;
  width: 100%;
  text-align: center;
  margin-bottom: 8vw;
  font-size: 4.8vw;
  font-weight: 500;
  font-family: PingFang SC;
}
.mobile-ecology #action .action-item {
  margin: 2.666667vw;
  width: 61.333333vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  opacity: 0.7;
}
.mobile-ecology #action .action-item img {
  width: 100%;
  height: 36vw;
}
.mobile-ecology #action .action-item .action-item-bottom {
  width: 100%;
  padding: 3.6vw;
  display: flex;
  flex-direction: column;
}
.mobile-ecology #action .action-item .action-item-bottom .action-item-title {
  font-size: 3.333333vw;
  font-weight: 500;
  color: #323232;
  margin-bottom: 2.133333vw;
}
.mobile-ecology #action .action-item .action-item-bottom .action-item-cont {
  font-size: 2.133333vw;
  color: #333333;
  line-height: 4.533333vw;
  text-align: justify;
}
.mobile-ecology #action .action-item .action-item-bottom .action-item-time {
  margin-top: 4vw;
  display: flex;
  justify-content: space-between;
}
.mobile-ecology #action .action-item .action-item-bottom .action-item-time span:nth-child(1) {
  font-size: 2.666667vw;
  color: #999999;
}
.mobile-ecology #action .action-item .action-item-bottom .action-item-time span:nth-child(2) {
  font-size: 2.666667vw;
  color: #1255ED;
}
.mobile-ecology #action .swiper .swiper-slide-active {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 2.666667vw;
  -webkit-transform: scale(1.12);
  transform: scale(1.12);
  transition-duration: 50% 50%;
}
.mobile-ecology #action .swiper .swiper-slide-active .action-item {
  opacity: 1;
}
.mobile-ecology #report {
  width: 100%;
  padding: 8vw;
  background: #132233 url('@/assets/ecology_4ping.png') no-repeat 100% 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.mobile-ecology #report .title {
  font-size: 4.8vw;
  font-weight: 500;
  color: #FFFFFF;
  margin-bottom: 8vw;
}
.mobile-ecology #report img {
  width: 100%;
  height: 59.733333vw;
  margin-bottom: 4vw;
}
.mobile-ecology #report .report-content {
  width: 100%;
  padding: 0 4vw;
  padding-top: 8vw;
  display: flex;
  flex-direction: column;
  background: #162541;
  opacity: 0.8;
}
.mobile-ecology #report .report-content a {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8vw;
}
.mobile-ecology #report .report-content a span {
  font-size: 3.733333vw;
  color: #FFFFFF;
}
.mobile-ecology #team {
  width: 100%;
  background: #132233 url('@/assets/ecologybg5.png') no-repeat 100% 100%;
  padding: 8vw 4vw;
  padding-bottom: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.mobile-ecology #team .title {
  font-size: 4.8vw;
  font-weight: 500;
  color: #FFFFFF;
  margin-bottom: 12vw;
}
.mobile-ecology #team .member-item {
  width: 100%;
  background: #293657;
  border-radius: 0.8vw;
  padding: 4vw;
  display: flex;
  flex-direction: column;
  margin-bottom: 8vw;
}
.mobile-ecology #team .member-item .member-top {
  width: 100%;
  position: relative;
  margin-bottom: 2.666667vw;
  height: 25.333333vw;
}
.mobile-ecology #team .member-item .member-top img {
  width: 33.333333vw;
  height: 33.333333vw;
  position: absolute;
  top: -8vw;
}
.mobile-ecology #team .member-item .member-top .member-top-right {
  width: 45.866667vw;
  float: right;
}
.mobile-ecology #team .member-item .member-top .member-top-right .member-title {
  display: flex;
  align-items: baseline;
  margin-bottom: 2.666667vw;
}
.mobile-ecology #team .member-item .member-top .member-top-right .member-title span:nth-child(1) {
  font-size: 4.266667vw;
  color: #FFFFFF;
  margin-right: 2.666667vw;
}
.mobile-ecology #team .member-item .member-top .member-top-right .member-title span:nth-child(2) {
  font-size: 3.2vw;
  color: #AAACB2;
}
.mobile-ecology #team .member-item .member-top .member-top-right .member-info {
  display: flex;
  flex-direction: column;
}
.mobile-ecology #team .member-item .member-top .member-top-right .member-info span {
  font-size: 3.2vw;
  color: #FFFFFF;
  line-height: 4.8vw;
}
.mobile-ecology #team .member-item .member-bottom {
  font-size: 3.2vw;
  color: #AAACB2;
  line-height: 4.8vw;
  text-align: justify;
}
.mobile-ecology #honor {
  width: 100%;
  background: #132233 url('@/assets/ecologybg6.png') no-repeat 100% 100%;
  padding: 8vw 4.533333vw;
  font-family: PingFang SC;
}
.mobile-ecology #honor .ecology-title {
  width: 100%;
  font-size: 4.8vw;
  font-weight: 500;
  color: #ffffff;
  margin-bottom: 8vw;
  text-align: center;
}
.mobile-ecology #honor .honorList li {
  font-size: 3.733333vw;
  color: #ffffff;
  margin-bottom: 6.666667vw;
}
.mobile-ecology #honor .honorList li:last-child {
  margin-bottom: 0;
}
