@-webkit-keyframes vibilbox {
  form {
    opacity: 1;
    visibility: visible;
  }
  to {
    opacity: 0;
  }
}
@keyframes vibilbox {
  form {
    opacity: 1;
    visibility: visible;
  }
  to {
    opacity: 0;
  }
}
.mobile-footer {
  width: 100%;
  background: #132233 url('@/assets/footer.png') no-repeat;
  display: flex;
  flex-direction: column;
}
.mobile-footer .mobile-footer-top {
  padding: 6.4vw 4vw;
}
.mobile-footer .mobile-footer-top .text-item {
  display: flex;
}
.mobile-footer .mobile-footer-top .text-item span {
  font-size: 3.2vw;
  line-height: 5.866667vw;
}
.mobile-footer .mobile-footer-top .text-item img {
  margin-top: 1.333333vw;
  width: 3.466667vw;
  max-height: 3.466667vw;
  margin-right: 2vw;
}
.mobile-footer .mobile-footer-top .mobile-footer-form {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 5.066667vw;
}
.mobile-footer .mobile-footer-top .mobile-footer-form .ant-select {
  width: 100%;
}
.mobile-footer .mobile-footer-top .mobile-footer-form .ant-select .ant-select-selector {
  border: 1px solid #5d5f61;
  width: 100%;
  height: 10.666667vw;
  background: transparent;
  padding: 0 4vw;
  margin-bottom: 2.666667vw;
  display: flex;
  align-items: center;
}
.mobile-footer .mobile-footer-top .mobile-footer-form .ant-select .ant-select-selector::-webkit-input-placeholder {
  color: #b1b4b7;
}
.mobile-footer .mobile-footer-top .mobile-footer-form .ant-select .ant-select-selector::placeholder {
  color: #b1b4b7;
}
.mobile-footer .mobile-footer-top .mobile-footer-form .ant-select .ant-select-arrow {
  margin-top: -2.666667vw;
  color: #B1B4B7;
}
.mobile-footer .mobile-footer-top .mobile-footer-form .ant-select .ant-select-selection-item {
  color: #fff;
  font-size: 3.2vw;
}
.mobile-footer .mobile-footer-top .mobile-footer-form .ant-select .ant-select-selection-placeholder {
  font-size: 3.2vw;
}
.mobile-footer .mobile-footer-top .mobile-footer-form .right-flex {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.mobile-footer .mobile-footer-top .mobile-footer-form .right-flex input,
.mobile-footer .mobile-footer-top .mobile-footer-form .right-flex input.active {
  width: 100%;
  height: 10.666667vw;
  border: 1px solid #5d5f61;
  background: transparent;
  margin-bottom: 2.666667vw;
  padding-left: 4vw;
  outline: none;
  font-size: 3.2vw;
}
.mobile-footer .mobile-footer-top .mobile-footer-form .right-flex input::-webkit-input-placeholder,
.mobile-footer .mobile-footer-top .mobile-footer-form .right-flex input.active::-webkit-input-placeholder {
  color: #B1B4B7;
}
.mobile-footer .mobile-footer-top .mobile-footer-form .right-flex input::placeholder,
.mobile-footer .mobile-footer-top .mobile-footer-form .right-flex input.active::placeholder {
  color: #B1B4B7;
}
.mobile-footer .mobile-footer-top .mobile-footer-form .right-flex input.active::-webkit-input-placeholder {
  color: red;
}
.mobile-footer .mobile-footer-top .mobile-footer-form .right-flex input.active::placeholder {
  color: red;
}
.mobile-footer .mobile-footer-top .mobile-footer-form .right-flex input.text-red {
  color: red;
}
.mobile-footer .mobile-footer-top .mobile-footer-form .big-input {
  border: 1px solid #5d5f61;
  padding: 3.733333vw 4vw;
  width: 100%;
  height: 25.333333vw;
  background: transparent;
  outline: none;
  font-size: 3.2vw;
}
.mobile-footer .mobile-footer-top .mobile-footer-form .big-input::-webkit-input-placeholder {
  color: #B1B4B7;
  font-size: 3.2vw;
}
.mobile-footer .mobile-footer-top .mobile-footer-form .big-input::placeholder {
  color: #B1B4B7;
  font-size: 3.2vw;
}
.mobile-footer .mobile-footer-top .mobile-footer-form .submit-box {
  width: 100%;
  font-size: 2.666667vw;
}
.mobile-footer .mobile-footer-top .mobile-footer-form .submit-box p {
  float: left;
  visibility: visible;
  margin-right: 2.666667vw;
  margin-bottom: 0;
}
.mobile-footer .mobile-footer-top .mobile-footer-form .submit-box .submit {
  font-size: 3.2vw;
  display: flex;
  width: 100%;
  height: 10.666667vw;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: #d98234;
  border: none;
  margin-top: 4vw;
}
.mobile-footer .buttom {
  font-size: 2.933333vw;
  width: 100%;
  height: 22.133333vw;
  border-top: 1px solid #5D5F61;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.mobile-footer .buttom span {
  font-size: 2.933333vw;
  line-height: 5.866667vw;
  text-decoration: underline;
}
.mobile-footer .buttom span:nth-child(1) {
  text-decoration: none;
}
.ant-select-item-option-content {
  color: #B1B4B7;
}
.ant-select-item-option-selected,
.ant-select-item-option-active {
  font-weight: normal!important;
  background-color: rgba(0, 0, 0, 0.3) !important;
}
