@-webkit-keyframes mybox {
  form {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  to {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }
}
@keyframes mybox {
  form {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  to {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }
}
.detail-item-1 img {
  width: 100%;
}
.detail-item-1 .bg-1 {
  position: relative;
}
.detail-item-1 .bg-1 .text {
  text-align: center;
  position: absolute;
  top: 19.375vw;
  left: 32.03125vw;
}
.detail-item-1 .bg-1 .text h2 {
  font-size: 2.34375vw;
  color: #ffffff;
}
.detail-item-1 .bg-2 {
  position: relative;
}
.detail-item-1 .bg-2 .text {
  position: absolute;
  top: 20.052083vw;
  left: 13.020833vw;
}
.detail-item-1 .bg-2 .text h3 {
  text-align: center;
  font-size: 2.604167vw;
  color: #ffffff;
  margin-bottom: 2.604167vw;
}
.detail-item-1 .bg-2 .text p {
  font-size: 1.145833vw;
}
.detail-item-1 .bg-3 {
  position: relative;
}
.detail-item-1 .bg-3 .text {
  position: absolute;
  top: 18.229167vw;
  right: 7.8125vw;
}
.detail-item-1 .bg-3 .text h3 {
  text-align: center;
  font-size: 2.604167vw;
  color: #ffffff;
  margin-bottom: 2.604167vw;
}
.detail-item-1 .bg-3 .text ul li {
  display: flex;
  font-size: 1.145833vw;
  margin-bottom: 1.041667vw;
}
.detail-item-1 .bg-3 .text ul li img {
  width: 1.302083vw;
  height: 1.302083vw;
  color: #ffffff;
  margin-right: 0.260417vw;
}
.detail-item-1 .bg-3 .text div {
  display: flex;
}
.detail-item-1 .bg-4 {
  position: relative;
}
.detail-item-1 .bg-4 .text {
  position: absolute;
  top: 13.541667vw;
  left: 13.020833vw;
}
.detail-item-1 .bg-4 .text h3 {
  text-align: center;
  font-size: 2.604167vw;
  color: #ffffff;
  margin-bottom: 2.604167vw;
}
.detail-item-1 .bg-4 .text p {
  font-size: 1.145833vw;
}
.detail-item-1 .bg-5 {
  position: relative;
}
.detail-item-1 .bg-5 .text {
  position: absolute;
  left: 7.604167vw;
  top: 12.5vw;
}
.detail-item-1 .bg-5 .text h3 {
  margin-left: 36.458333vw;
  font-size: 2.604167vw;
  color: #ffffff;
  margin-bottom: 4.166667vw;
}
.detail-item-1 .bg-5 .text p {
  font-size: 1.145833vw;
  margin-bottom: 3.125vw;
  line-height: 2.34375vw;
}
.detail-item-1 .bg-5 .text ul li {
  display: flex;
  font-size: 1.145833vw;
  margin-bottom: 1.041667vw;
}
.detail-item-1 .bg-5 .text ul li img {
  width: 1.302083vw;
  height: 1.302083vw;
  color: #ffffff;
  margin-right: 0.260417vw;
}
