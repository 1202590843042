ul,
li {
  padding: 0;
  margin: 0;
  list-style: none;
}
.hiddenInput {
  opacity: 0;
}
.home {
  height: auto;
  width: 100%;
  scroll-behavior: smooth !important;
}
.home .banner {
  height: 100vh;
  width: 100%;
  background: #132233 url('@/assets/miniSizeImg/bg_1ping.jpg') no-repeat;
  background-size: cover;
}
.home .introduction {
  height: 130vh;
  width: 100%;
  background: #132233 url('@/assets/miniSizeImg/bg_2ping.jpg') no-repeat;
  background-size: cover;
}
.home .stata {
  height: 200vh;
  width: 100%;
  background: #132233 url('@/assets/ecology_3ping.png') no-repeat;
  background-size: cover;
}
.home .repart {
  height: 120vh;
  width: 100%;
  background: #132233 url('@/assets/ecology_4ping.png') no-repeat;
  background-size: cover;
}
.home .team {
  height: 150vh;
  width: 100%;
  background: #132233 url('@/assets/ecology_5ping.png') no-repeat;
  background-size: cover;
}
.home .honor {
  height: 120vh;
  width: 100%;
  background: #132233 url('@/assets/miniSizeImg/bg_6ping.jpg') no-repeat;
  background-size: cover;
}
.home .honor .honorList {
  width: 80%;
  margin: auto;
}
.home .honor .honorList li {
  padding: 1.041667vw;
  font-size: 1.302083vw;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
}
.banner-content {
  position: absolute;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.banner-content span {
  color: #fff;
  text-align: center;
}
.banner-content .banner-title {
  font-size: 3.75vw;
  margin-bottom: 0.833333vw;
}
.banner-content .banner-subtitle {
  font-size: 1.25vw;
  margin-bottom: 1.875vw;
  line-height: 2.5vw;
  color: #1154ed;
}
.banner-content .bigFootSizeSpan {
  font-size: xx-large;
}
.banner-content .topContent {
  height: 73vh !important;
}
.banner-content .mechanismImg-row {
  width: 90%;
  margin: auto;
  margin-top: 3.125vw;
}
.banner-content .mechanismImg-row .ant-col {
  text-align: center;
}
.banner-content .mechanismImg-row .ant-col div:last-child {
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
  margin-top: 1.041667vw;
}
.banner-content .mechanismImg-row img {
  width: 6.770833vw;
}
.banner-content .banner-content-background {
  display: inline-block;
  width: 85%;
  text-align: left;
  background-color: #1e2a51;
  padding: 1.875vw;
}
.banner-content .banner-content-background span {
  display: inline-block;
  text-indent: 2em;
  text-align: left !important;
  line-height: 2.2em;
}
.banner-content .banner-des {
  font-size: 1.25vw;
  line-height: 2.5vw;
}
.banner-content .banner-tab {
  margin-top: 0.885417vw;
}
.banner-content .banner-tab .tab-top {
  display: flex;
  margin-bottom: 1.041667vw;
}
.banner-content .banner-tab .tab-bottom {
  display: flex;
  justify-content: center;
}
.banner-content .banner-tab span:first-child {
  display: inline-block;
  width: 6.25vw;
  line-height: 2.8125vw;
  color: #133363;
  font-size: 1.458333vw;
  background: #70d9fc;
  margin-left: 1.041667vw;
}
.banner-content .banner-tab span:nth-child(2) {
  display: inline-block;
  width: 7.03125vw;
  line-height: 2.291667vw;
  border: 1px solid #70d9fc;
  font-size: 0.9375vw;
  color: #70d9fc;
}
.navCoDiv {
  height: 9.375vw;
  padding-top: 4.166667vw;
}
.navCoDiv ul {
  height: 5.208333vw;
  width: 80%;
  border-bottom: 0.104167vw solid #586577;
  margin: auto;
  list-style: none;
  padding: 0 2.604167vw;
  z-index: 99;
  box-sizing: border-box;
}
.navCoDiv ul li {
  width: 20%;
  text-align: center;
  float: left;
  height: 100%;
  font-size: 2.083333vw;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
  line-height: 3.125vw;
  padding: 0 2.083333vw;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  z-index: 999;
}
.navCoDiv ul li > span {
  height: 100%;
  display: inline-block;
}
.navCoDiv ul .checkedThatLi {
  border-bottom: 0.208333vw solid #1154ed;
  color: #1154ed;
  z-index: 999;
  position: relative;
  top: 0px;
}
.ecology-title-div {
  width: 80%;
  height: 23vw;
  font-size: 2.604167vw;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
  line-height: 23vw;
  margin: auto;
  text-align: left;
  -webkit-user-select: none;
  user-select: none;
}
.ecology-title-div span {
  border-bottom: 0.208333vw solid #1154ed;
  text-align: center;
  padding-bottom: 0.15625vw;
}
.ecology-title-divt {
  font-size: 2.604167vw;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
  margin-left: 10%;
  padding-top: 5.208333vw;
  text-align: left;
  -webkit-user-select: none;
  user-select: none;
}
.ecology-title-divt span {
  border-bottom: 0.208333vw solid #1154ed;
  text-align: center;
  padding-bottom: 0.15625vw;
}
.ecology-content-div {
  width: 80%;
  margin: auto;
}
.ecology-content-div-row-left {
  width: 100%;
}
.ecology-content-div-row-left > img {
  width: 100%;
}
.ecology-content-div-row-right {
  width: 100%;
  height: 100%;
}
.ecology-content-div-row-right span {
  display: inline-block;
  text-indent: 2em;
  text-align: left !important;
  line-height: 2.2em;
  font-family: Source Han Sans CN;
  font-weight: 600;
}
.ecology-content-div-row-right .CoStateContent {
  position: absolute;
  top: 0;
  width: 60%;
  right: 13%;
  top: 24%;
}
.ecology-content-div-row-right .CoStateContent span {
  text-indent: 0;
  color: #636466;
}
.ecology-content-div-row-right .readMore {
  margin-top: 2.083333vw;
  width: 10.416667vw;
  height: 3.125vw;
  color: #1154ed;
  border: 0.104167vw solid #1154ed;
  line-height: 3.125vw;
  text-align: center;
  cursor: pointer;
}
.stateCardDiv .ant-col {
  cursor: pointer;
}
.stata-card-div {
  width: 80%;
  margin: auto;
  margin-top: 10.416667vw;
}
.stata-card-div .ant-col {
  color: #636466;
}
.stata-card-div .ant-col img {
  width: 100%;
}
.stata-card-div .ant-col .stateCardDiv-content {
  padding: 1.041667vw;
  background-color: #fff;
}
.stata-card-div .ant-col .stateCardDiv-content-span {
  margin-left: 8.072917vw;
}
.stata-card-div .ant-col span:last-child {
  display: inline-block;
  font-weight: 400;
  font-family: Source Han Sans CN;
  color: #636466;
  text-align: left !important;
  text-indent: 2em;
}
.repartContent {
  width: 80%;
  margin: auto;
}
.repartContent .repartContentList li {
  padding: 1.041667vw;
  font-family: Source Han Sans CN;
  font-weight: 600;
  color: #aaacb2;
  font-size: 0.9375vw;
  cursor: pointer;
}
.repartContent .repartContentImg {
  width: 100%;
}
.repartContent .repartContentImg img {
  width: 80%;
}
.repartContent .checkRepartContent {
  color: #1255ed !important;
  width: 14.322917vw;
  height: 4.166667vw;
  background: #101b2f;
  border-radius: 0.208333vw;
  font-family: Source Han Sans CN;
  font-weight: 600;
  line-height: 2.083333vw !important;
  font-size: 1.302083vw !important;
  border-left: 0.104167vw solid #1255ed;
}
.teamRow {
  width: 80%;
  margin: auto;
}
.teamRow .teamRowImg {
  z-index: 300;
  width: 40%;
  /*   width: 15.927083vw; */
  height: 19.895833vw;
  float: left;
  border-radius: 0.260417vw;
}
.teamRow .teamRowImg img {
  width: 100%;
  height: 100%;
  z-index: 400;
}
.teamRow .teamRowImg .teamImg5,
.teamRow .teamRowImg .teamImg6 {
  padding: 0.8vw;
}
.teamRow .teamRowInfo {
  padding: 1.041667vw;
  z-index: 100;
  position: absolute;
  width: 60%;
  right: 0.78125vw;
  bottom: 0.78125vw;
  background: #293657;
  border-radius: 0 0.520833vw 0.520833vw 0;
}
.teamRow .teamRowInfo .teamRowInfo-name {
  padding: 1.041667vw 0px;
  color: #ffffff;
  font-size: 1.302083vw;
  font-family: Source Han Sans CN;
}
.teamRow .teamRowInfo .teamRowInfo-middleDiv {
  height: 5.208333vw;
  font-size: 1.041667vw;
  color: #fff;
  padding: 0.104167vw;
}
.teamRow .teamRowInfo .teamRowInfo-div {
  line-height: 1.5625vw;
  margin-top: 0.520833vw;
  color: #bfbfbf;
  font-size: 0.625vw;
}
.teamRow .teamRowInfo span {
  display: inline-block;
}
