.home {
  height: auto;
  width: 100%;
}
.home .research {
  height: 100vh;
  width: 100%;
  background: #132233 url("@/assets/miniSizeImg/academy_1ping.jpg") no-repeat;
  background-size: cover;
}
.home .field {
  height: 100vh;
  width: 100%;
  background: #132233 url("@/assets/miniSizeImg/academy_2ping.jpg") no-repeat;
  background-size: cover;
}
.home .ability {
  height: 95vh;
  width: 100%;
  background: #132233 url("@/assets/academy_3ping.png") no-repeat;
  background-size: cover;
}
.home .mechanism {
  height: 100vh;
  width: 100%;
  background: #132233 url("@/assets/miniSizeImg/academy_4ping.jpg") no-repeat;
  background-size: cover;
}
.banner-content {
  position: absolute;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.banner-content span {
  color: #fff;
  text-align: center;
}
.banner-content .banner-title {
  font-size: 3.75vw;
  margin-bottom: 0.833333vw;
}
.banner-content .banner-subtitle {
  font-size: 1.25vw;
  margin-bottom: 1.875vw;
  line-height: 2.5vw;
}
.banner-content .bigFootSizeSpan {
  font-size: xx-large;
}
.banner-content .topContent {
  height: 73vh !important;
}
.banner-content .mechanismImg-row {
  width: 90%;
  margin: auto;
  margin-top: 3.125vw;
}
.banner-content .mechanismImg-row .ant-col {
  text-align: center;
}
.banner-content .mechanismImg-row .ant-col div:last-child {
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #FFFFFF;
  margin-top: 1.041667vw;
}
.banner-content .mechanismImg-row img {
  width: 6.770833vw;
}
.banner-content .banner-content-background {
  display: inline-block;
  width: 85%;
  text-align: left;
  background-color: #1E2A51;
  padding: 1.875vw;
}
.banner-content .banner-content-background span {
  display: inline-block;
  text-indent: 2em;
  text-align: left !important;
  line-height: 2.2em;
}
.banner-content .banner-des {
  font-size: 1.25vw;
  line-height: 2.5vw;
}
.banner-content .banner-tab {
  margin-top: 0.885417vw;
}
.banner-content .banner-tab .tab-top {
  display: flex;
  margin-bottom: 1.041667vw;
}
.banner-content .banner-tab .tab-bottom {
  display: flex;
  justify-content: center;
}
.banner-content .banner-tab span:first-child {
  display: inline-block;
  width: 6.25vw;
  line-height: 2.8125vw;
  color: #133363;
  font-size: 1.458333vw;
  background: #70D9FC;
  margin-left: 1.041667vw;
}
.banner-content .banner-tab span:nth-child(2) {
  display: inline-block;
  width: 7.03125vw;
  line-height: 2.291667vw;
  border: 1px solid #70D9FC;
  font-size: 0.9375vw;
  color: #70D9FC;
}
