.m-detail-item-2 .bg-1 .text {
  position: absolute;
  top: 50.666667vw;
  left: 93.333333vw;
}
.m-detail-item-3 .bg-1 .text {
  position: absolute;
  top: 53.333333vw;
  left: 98.666667vw;
}
