.annotationall {
  overflow: hidden;
}
.annotationall .annotation-top .annotation-banner,
.annotationall .annotation-top .annotation-banner1,
.annotationall .annotation-top .annotation-banner2,
.annotationall .annotation-top .annotation-banner3,
.annotationall .annotation-top .annotation-banner4,
.annotationall .annotation-top .annotation-banner5 {
  position: relative;
}
.annotationall .annotation-top .annotation-banner img,
.annotationall .annotation-top .annotation-banner1 img,
.annotationall .annotation-top .annotation-banner2 img,
.annotationall .annotation-top .annotation-banner3 img,
.annotationall .annotation-top .annotation-banner4 img,
.annotationall .annotation-top .annotation-banner5 img {
  width: 100%;
  height: 100%;
}
.annotationall .annotation-top .annotation-banner .maodian,
.annotationall .annotation-top .annotation-banner1 .maodian,
.annotationall .annotation-top .annotation-banner2 .maodian,
.annotationall .annotation-top .annotation-banner3 .maodian,
.annotationall .annotation-top .annotation-banner4 .maodian,
.annotationall .annotation-top .annotation-banner5 .maodian {
  position: absolute;
  top: -2.604167vw;
  left: 0px;
}
.annotationall .annotation-top .annotation-banner .annotation-baner-title,
.annotationall .annotation-top .annotation-banner1 .annotation-baner-title,
.annotationall .annotation-top .annotation-banner2 .annotation-baner-title,
.annotationall .annotation-top .annotation-banner3 .annotation-baner-title,
.annotationall .annotation-top .annotation-banner4 .annotation-baner-title,
.annotationall .annotation-top .annotation-banner5 .annotation-baner-title {
  position: absolute;
  font-size: 3.75vw;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 3.125vw;
}
.annotationall .annotation-top .annotation-banner .annotation-baner-txt,
.annotationall .annotation-top .annotation-banner1 .annotation-baner-txt,
.annotationall .annotation-top .annotation-banner2 .annotation-baner-txt,
.annotationall .annotation-top .annotation-banner3 .annotation-baner-txt,
.annotationall .annotation-top .annotation-banner4 .annotation-baner-txt,
.annotationall .annotation-top .annotation-banner5 .annotation-baner-txt {
  position: absolute;
  font-size: 1.25vw;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 2.5vw;
}
.annotationall .annotation-top .annotation-banner .annotation-baner-tag,
.annotationall .annotation-top .annotation-banner1 .annotation-baner-tag,
.annotationall .annotation-top .annotation-banner2 .annotation-baner-tag,
.annotationall .annotation-top .annotation-banner3 .annotation-baner-tag,
.annotationall .annotation-top .annotation-banner4 .annotation-baner-tag,
.annotationall .annotation-top .annotation-banner5 .annotation-baner-tag {
  position: absolute;
}
.annotationall .annotation-top .annotation-banner .annotation-baner-tag .annotation-baner-intag,
.annotationall .annotation-top .annotation-banner1 .annotation-baner-tag .annotation-baner-intag,
.annotationall .annotation-top .annotation-banner2 .annotation-baner-tag .annotation-baner-intag,
.annotationall .annotation-top .annotation-banner3 .annotation-baner-tag .annotation-baner-intag,
.annotationall .annotation-top .annotation-banner4 .annotation-baner-tag .annotation-baner-intag,
.annotationall .annotation-top .annotation-banner5 .annotation-baner-tag .annotation-baner-intag {
  padding: 0px 1.5625vw;
  margin: 0px 0.520833vw 0.520833vw 0px;
  display: inline-block;
  border: 1px solid #70E3FC;
  font-size: 1.25vw;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #70E3FC;
  line-height: 3.125vw;
}
.annotationall .annotation-top .annotation-banner .annotation-baner-title {
  top: 12.760417vw;
  left: 42.5vw;
}
.annotationall .annotation-top .annotation-banner .annotation-baner-txt {
  top: 17.760417vw;
  left: 26.5625vw;
}
.annotationall .annotation-top .annotation-banner .annotation-baner-toptagall {
  position: absolute;
  width: 100%;
  height: 14.583333vw;
  top: 23.072917vw;
  display: flex;
  flex-direction: row;
}
.annotationall .annotation-top .annotation-banner .annotation-baner-toptagall .annotation-baner-toptag {
  display: inline-block;
  width: 16.25vw;
  height: 7.03125vw;
  font-size: 1.5625vw;
}
.annotationall .annotation-top .annotation-banner .annotation-baner-toptag:nth-child(1) {
  margin-top: 0.677083vw;
  margin-left: 5.052083vw;
  background: url(../../../assets/tupianbiaozhu.png) no-repeat;
  background-size: 100% 100%;
}
.annotationall .annotation-top .annotation-banner .annotation-baner-toptag:nth-child(2) {
  margin-left: 2.864583vw;
  margin-top: 3.229167vw;
  background: url(../../../assets/yinpinbiaozhu.png) no-repeat;
  background-size: 100% 100%;
}
.annotationall .annotation-top .annotation-banner .annotation-baner-toptag:nth-child(3) {
  margin-left: 2.03125vw;
  margin-top: 6.25vw;
  background: url(../../../assets/wenbenbiaozhu.png) no-repeat;
  background-size: 100% 100%;
}
.annotationall .annotation-top .annotation-banner .annotation-baner-toptag:nth-child(4) {
  margin-left: 0.3125vw;
  margin-top: 1.40625vw;
  background: url(../../../assets/shipinbiaozhu.png) no-repeat;
  background-size: 100% 100%;
}
.annotationall .annotation-top .annotation-banner .annotation-baner-toptag:nth-child(5) {
  margin-left: 4.53125vw;
  background: url(../../../assets/zidongjiashi.png) no-repeat;
  background-size: 100% 100%;
}
.annotationall .annotation-top .annotation-banner1 .annotation-baner-title {
  top: 15.15625vw;
  left: 8.854167vw;
}
.annotationall .annotation-top .annotation-banner1 .annotation-baner-tag {
  top: 21.354167vw;
  left: 8.854167vw;
}
.annotationall .annotation-top .annotation-banner1 .annotation-baner-txt {
  top: 28.645833vw;
  left: 8.854167vw;
}
.annotationall .annotation-top .annotation-banner2 .annotation-baner-title {
  top: 11.927083vw;
  left: 49.53125vw;
}
.annotationall .annotation-top .annotation-banner2 .annotation-baner-tag {
  top: 19.270833vw;
  left: 49.53125vw;
}
.annotationall .annotation-top .annotation-banner2 .annotation-baner-txt {
  top: 26.5625vw;
  left: 49.53125vw;
}
.annotationall .annotation-top .annotation-banner3 .annotation-baner-title {
  top: 13.59375vw;
  left: 8.854167vw;
}
.annotationall .annotation-top .annotation-banner3 .annotation-baner-tag {
  width: 46.875vw;
  top: 19.791667vw;
  left: 8.854167vw;
}
.annotationall .annotation-top .annotation-banner3 .annotation-baner-txt {
  top: 28.125vw;
  left: 8.854167vw;
}
.annotationall .annotation-top .annotation-banner4 .annotation-baner-title {
  top: 11.666667vw;
  left: 56.666667vw;
}
.annotationall .annotation-top .annotation-banner4 .annotation-baner-tag {
  top: 18.75vw;
  left: 56.666667vw;
}
.annotationall .annotation-top .annotation-banner4 .annotation-baner-txt {
  top: 25.520833vw;
  left: 56.666667vw;
}
.annotationall .annotation-top .annotation-banner5 .annotation-baner-title {
  top: 12.65625vw;
  left: 8.854167vw;
}
.annotationall .annotation-top .annotation-banner5 .annotation-baner-tag {
  width: 44.270833vw;
  top: 18.75vw;
  left: 8.854167vw;
}
.annotationall .annotation-top .annotation-banner5 .annotation-baner-txt {
  top: 27.083333vw;
  left: 8.854167vw;
}
