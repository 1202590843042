@-webkit-keyframes mybox {
  form {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  to {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }
}
@keyframes mybox {
  form {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  to {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }
}
html {
  scroll-behavior: smooth;
}
.mobile-case .banner-1 {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.mobile-case .banner-1 img {
  position: absolute;
  width: 100%;
  height: 100%;
}
.mobile-case .banner-1 .big-img {
  -webkit-animation: mybox 3s forwards;
  animation: mybox 3s forwards;
}
.mobile-case .banner-1 .title {
  font-size: 7.333333vw;
  margin-bottom: 8.266667vw;
}
.mobile-case .banner-1 .sub-title {
  font-size: 3.733333vw;
  line-height: 8vw;
  text-align: center;
}
.mobile-case .banner-2 {
  position: relative;
  width: 100%;
  padding: 13.333333vw 5.333333vw;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background: url("@/assets/casebg_2.png") no-repeat;
  background-size: 100% 100%;
}
.mobile-case .banner-2 .banner-2-bg {
  position: absolute;
  width: 100%;
  height: 100%;
}
.mobile-case .banner-2 .app-item {
  width: 100%;
  margin-bottom: 5.333333vw;
}
.mobile-case .banner-2 .app-item .top {
  width: 100%;
  height: 52vw;
}
.mobile-case .banner-2 .app-item .top img {
  width: 100%;
  height: 100%;
}
.mobile-case .banner-2 .app-item .buttom {
  width: 100%;
  padding: 4.533333vw 5.333333vw;
  background: #fff;
}
.mobile-case .banner-2 .app-item .buttom h4 {
  font-size: 4vw;
  color: #353535;
  margin-bottom: 2.933333vw;
}
.mobile-case .banner-2 .app-item .buttom p {
  font-size: 3.2vw;
  color: #636466;
  line-height: 4.8vw;
  margin-bottom: 3.466667vw;
}
.mobile-case .banner-2 .app-item .buttom .footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.mobile-case .banner-2 .app-item .buttom .footer span {
  color: #949699;
  font-size: 3.2vw;
}
.mobile-case .banner-2 .app-item .buttom .footer .botton {
  width: 21.333333vw;
  height: 7.466667vw;
  border: 0.266667vw solid #1154ED;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mobile-case .banner-2 .app-item .buttom .footer .botton div {
  color: #1154ed;
  font-size: 3.2vw;
}
.mobile-case .banner-2 .app-item .buttom .footer .botton img {
  width: 3.2vw;
  height: 1.066667vw;
  margin-left: 1.333333vw;
}
.mobile-case .banner-2 .app-item:last-child {
  margin-bottom: 0;
}
