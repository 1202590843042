@-webkit-keyframes mybox {
  form {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  to {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }
}
@keyframes mybox {
  form {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  to {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }
}
.m-detail-item-1 .bg-1,
.m-detail-item-1 .bg-3,
.m-detail-item-1 .bg-2,
.m-detail-item-1 .bg-4,
.m-detail-item-1 .bg-5 {
  position: relative;
  width: 100%;
  height: 133.333333vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.m-detail-item-1 .bg-1 img,
.m-detail-item-1 .bg-3 img,
.m-detail-item-1 .bg-2 img,
.m-detail-item-1 .bg-4 img,
.m-detail-item-1 .bg-5 img {
  position: absolute;
  width: 100%;
  height: 100%;
}
.m-detail-item-1 .bg-1 .text,
.m-detail-item-1 .bg-3 .text,
.m-detail-item-1 .bg-2 .text,
.m-detail-item-1 .bg-4 .text,
.m-detail-item-1 .bg-5 .text {
  position: absolute;
}
.m-detail-item-1 .bg-1 .text h2,
.m-detail-item-1 .bg-3 .text h2,
.m-detail-item-1 .bg-2 .text h2,
.m-detail-item-1 .bg-4 .text h2,
.m-detail-item-1 .bg-5 .text h2 {
  line-height: 8vw;
  margin: 0;
  font-family: PingFang SC;
  font-weight: 400;
  font-size: 4.8vw;
  color: #ffffff;
  text-align: center;
}
.m-detail-item-1 .bg-1 .text h3,
.m-detail-item-1 .bg-3 .text h3,
.m-detail-item-1 .bg-2 .text h3,
.m-detail-item-1 .bg-4 .text h3,
.m-detail-item-1 .bg-5 .text h3 {
  text-align: center;
  font-size: 4.8vw;
  color: #ffffff;
  margin-bottom: 2.933333vw;
  margin-top: 16vw;
}
.m-detail-item-1 .bg-1 .text {
  margin-top: 38.133333vw;
}
.m-detail-item-1 .bg-2 .text span,
.m-detail-item-1 .bg-4 .text span,
.m-detail-item-1 .bg-5 .text span {
  display: inline-block;
  padding: 0 8vw;
  font-size: 3.733333vw;
  margin: 0;
  line-height: 6.4vw;
  opacity: 0.8;
  text-align: justify;
}
.m-detail-item-1 .bg-3 .text .tong-item,
.m-detail-item-1 .bg-5 .text .tong-item {
  padding-left: 12vw;
  padding-right: 8vw;
}
.m-detail-item-1 .bg-3 .text .tong-item li,
.m-detail-item-1 .bg-5 .text .tong-item li {
  list-style-type: square;
  font-size: 3.733333vw;
  font-family: PingFang SC;
  line-height: 6.4vw;
  opacity: 0.8;
  text-align: justify;
}
.m-detail-item-1 .bg-3 .text .tong-item,
.m-detail-item-1 .bg-5 .text .tong-item {
  margin-top: 2.666667vw;
}
.m-detail-item-1 .bg-3,
.m-detail-item-1 .bg-4 {
  height: 144vw;
}
