div {
  margin: 0;
  padding: 0;
}
ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
ul li {
  margin: 0;
  padding: 0;
}
@-webkit-keyframes mybox {
  form {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  to {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }
}
@keyframes mybox {
  form {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  to {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }
}
.datacenter img {
  width: 100%;
  height: 100%;
}
.datacenter .item-1 {
  position: relative;
  overflow: hidden;
}
.datacenter .item-1 img {
  width: 100%;
  height: 100%;
}
.datacenter .item-1 .big-img {
  -webkit-animation: mybox 3s forwards;
  animation: mybox 3s forwards;
}
.datacenter .item-1 .postion {
  position: absolute;
  width: 100%;
  top: 20.78125vw;
  text-align: center;
}
.datacenter .item-1 .postion h2 {
  font-size: 3.645833vw;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
  margin-left: 3.125vw;
}
.datacenter .item-1 .postion div {
  font-size: 1.25vw;
  margin-bottom: 0.520833vw;
}
.datacenter .item-2 {
  margin-top: -1px;
  position: relative;
}
.datacenter .item-2 .data-list {
  z-index: 2;
  position: absolute;
  display: flex;
  padding: 0 1.614583vw 0 2.604167vw;
  top: -3.90625vw;
}
.datacenter .item-2 .data-list div {
  padding-top: 1.458333vw;
  width: 12.5vw;
  height: 7.8125vw;
  background: #03529c;
  border-radius: 0.520833vw;
  margin-right: 0.989583vw;
  text-align: center;
}
.datacenter .item-2 .data-list div:hover {
  -webkit-transform: translateY(-0.416667vw);
  transform: translateY(-0.416667vw);
  transition: 0.3s;
  cursor: pointer;
}
.datacenter .item-2 .data-list div.active {
  background: linear-gradient(135deg, #12b2ed 0%, #0930c5 100%);
  border: 0.104167vw solid #ffffff;
  border-radius: 0.520833vw;
}
.datacenter .item-2 .data-list div a {
  display: inline-block;
  width: 100%;
  height: 100%;
}
.datacenter .item-2 .data-list div a h5 {
  font-size: 1.5625vw;
  margin: 0;
  padding: 0;
  color: #fff;
}
.datacenter .item-2 .data-list div a p {
  color: #fff;
  font-size: 1.302083vw;
}
.datacenter .item-2 .item-h2 {
  width: 100%;
  position: absolute;
  top: 9.739583vw;
  text-align: center;
}
.datacenter .item-2 .item-h2 h2 {
  color: #fff;
  font-size: 3.645833vw;
  font-family: Source Han Sans CN;
  font-weight: 400;
}
.datacenter .item-2 .postion {
  position: absolute;
  top: 9.739583vw;
  left: 7.03125vw;
}
.datacenter .item-2 .postion .syllsj {
  margin-top: 9.895833vw;
  position: relative;
  margin-bottom: 2.552083vw;
}
.datacenter .item-2 .postion .syllsj img {
  height: 17.1875vw;
  width: 11.458333vw;
}
.datacenter .item-2 .postion .syllsj ul {
  position: absolute;
  top: 2.604167vw;
  left: 1.5625vw;
}
.datacenter .item-2 .postion .syllsj ul li {
  color: white;
  width: 8.333333vw;
  height: 2.552083vw;
  background-color: rgba(18, 85, 237, 0.2);
  border-radius: 0.260417vw;
  margin-bottom: 0.78125vw;
  line-height: 2.552083vw;
  text-align: center;
}
.datacenter .item-2 .postion .bqhx {
  position: absolute;
  top: 10.15625vw;
  left: 18.75vw;
}
.datacenter .item-2 .postion .bqhx img {
  height: 15.104167vw;
  width: 12.5vw;
}
.datacenter .item-2 .postion .bqhx ul {
  position: absolute;
  top: 2.604167vw;
  left: 2.083333vw;
}
.datacenter .item-2 .postion .bqhx ul li {
  color: white;
  width: 8.333333vw;
  height: 2.552083vw;
  background-color: rgba(18, 85, 237, 0.2);
  border-radius: 0.260417vw;
  margin-bottom: 0.78125vw;
  line-height: 2.552083vw;
  text-align: center;
}
.datacenter .item-2 .postion .yfsj {
  position: relative;
}
.datacenter .item-2 .postion .yfsj img {
  height: 11.458333vw;
  width: 31.25vw;
}
.datacenter .item-2 .postion .yfsj ul {
  display: flex;
  position: absolute;
  top: 2.604167vw;
  left: 1.5625vw;
  flex-wrap: wrap;
}
.datacenter .item-2 .postion .yfsj ul li {
  color: white;
  width: 8.333333vw;
  height: 2.552083vw;
  background-color: rgba(18, 85, 237, 0.2);
  border-radius: 0.260417vw;
  margin-bottom: 0.78125vw;
  margin-right: 1.458333vw;
  line-height: 2.552083vw;
  text-align: center;
}
.datacenter .item-2 .postion .sjzt {
  position: absolute;
  top: 19.010417vw;
  left: 38.541667vw;
}
.datacenter .item-2 .postion .sjzt img {
  height: 15.46875vw;
  width: 12.5vw;
}
.datacenter .item-2 .postion .sjzt ul {
  position: absolute;
  top: 2.96875vw;
  left: 2.083333vw;
}
.datacenter .item-2 .postion .sjzt ul li {
  color: white;
  width: 8.333333vw;
  height: 2.552083vw;
  background-color: rgba(18, 85, 237, 0.2);
  border-radius: 0.260417vw;
  margin-bottom: 0.78125vw;
  line-height: 2.552083vw;
  text-align: center;
}
.datacenter .item-2 .postion .yxzdh {
  position: absolute;
  top: 10.15625vw;
  left: 57.291667vw;
}
.datacenter .item-2 .postion .yxzdh img {
  height: 15.104167vw;
  width: 12.5vw;
}
.datacenter .item-2 .postion .yxzdh ul {
  position: absolute;
  top: 2.604167vw;
  left: 2.083333vw;
}
.datacenter .item-2 .postion .yxzdh ul li {
  color: white;
  width: 8.333333vw;
  height: 2.552083vw;
  background-color: rgba(18, 85, 237, 0.2);
  border-radius: 0.260417vw;
  margin-bottom: 0.78125vw;
  line-height: 2.552083vw;
  text-align: center;
}
.datacenter .item-2 .postion .ksh {
  position: absolute;
  top: 26.5625vw;
  left: 57.291667vw;
}
.datacenter .item-2 .postion .ksh img {
  height: 15.104167vw;
  width: 12.5vw;
}
.datacenter .item-2 .postion .ksh ul {
  position: absolute;
  top: 2.604167vw;
  left: 2.083333vw;
}
.datacenter .item-2 .postion .ksh ul li {
  color: white;
  width: 8.333333vw;
  height: 2.552083vw;
  background-color: rgba(18, 85, 237, 0.2);
  border-radius: 0.260417vw;
  margin-bottom: 0.78125vw;
  line-height: 2.552083vw;
  text-align: center;
}
.datacenter .item-2 .postion .jw {
  position: absolute;
  top: 10.677083vw;
  left: 76.041667vw;
}
.datacenter .item-2 .postion .jw img {
  height: 31.25vw;
  width: 12.5vw;
}
.datacenter .item-2 .postion .jw ul {
  position: absolute;
  top: 2.604167vw;
  left: 2.083333vw;
}
.datacenter .item-2 .postion .jw ul li {
  font-size: 1.197917vw;
  color: white;
  width: 8.385417vw;
  height: 3.697917vw;
  background: linear-gradient(90deg, #12b2ed, #0930c5);
  border-radius: 0.260417vw;
  margin-bottom: 3.125vw;
  line-height: 3.697917vw;
  text-align: center;
}
.datacenter .item-2 .jt1 {
  position: absolute;
  width: 3.125vw;
  height: 2.291667vw;
  top: 26.041667vw;
  left: 20.625vw;
}
.datacenter .item-2 .jt2 {
  position: absolute;
  width: 3.125vw;
  height: 2.291667vw;
  top: 31.25vw;
  left: 40.104167vw;
}
.datacenter .item-2 .jt3 {
  position: absolute;
  width: 3.125vw;
  height: 2.291667vw;
  top: 40.364583vw;
  left: 40.104167vw;
}
.datacenter .item-2 .jt4 {
  position: absolute;
  width: 3.125vw;
  height: 2.291667vw;
  top: 31.25vw;
  right: 36.458333vw;
}
.datacenter .item-2 .jt5 {
  position: absolute;
  width: 3.125vw;
  height: 2.291667vw;
  top: 40.364583vw;
  right: 36.458333vw;
}
.datacenter .item-2 .jt6 {
  position: absolute;
  width: 3.125vw;
  height: 2.291667vw;
  top: 31.25vw;
  right: 17.1875vw;
}
.datacenter .item-2 .jt7 {
  position: absolute;
  width: 3.125vw;
  height: 2.291667vw;
  top: 40.364583vw;
  right: 17.1875vw;
}
.datacenter .item-3 {
  margin-top: -1px;
  position: relative;
}
.datacenter .item-3 .item-h2 {
  width: 100%;
  position: absolute;
  top: 6.40625vw;
  text-align: center;
}
.datacenter .item-3 .item-h2 h2 {
  color: #fff;
  font-size: 3.645833vw;
  font-family: Source Han Sans CN;
  font-weight: 400;
}
.datacenter .item-3 ul {
  display: flex;
  position: absolute;
  top: 16.197917vw;
  left: 7.760417vw;
}
.datacenter .item-3 ul li {
  width: 26.041667vw;
  height: 17.8125vw;
  margin-right: 3.125vw;
}
.datacenter .item-3 ul li h4 {
  font-size: 1.5625vw;
  font-weight: 400;
  color: #ffffff;
  margin-bottom: 1.5625vw;
}
.datacenter .item-3 ul li .position {
  position: absolute;
  top: 2.34375vw;
  left: 3.645833vw;
}
.datacenter .item-3 ul li .position img {
  width: 0.9375vw;
  height: 0.9375vw;
  margin-right: 0.260417vw;
}
.datacenter .item-3 ul li .position .hengxian {
  width: 3.125vw;
  height: 0.104167vw;
  background: #ffffff;
  margin-bottom: 2.083333vw;
}
.datacenter .item-3 ul li .position .item {
  margin-bottom: 1.302083vw;
}
.datacenter .item-3 ul li .position-2 {
  position: absolute;
  top: 2.34375vw;
  left: 32.8125vw;
}
.datacenter .item-3 ul li .position-2 img {
  width: 0.9375vw;
  height: 0.9375vw;
  margin-right: 0.260417vw;
}
.datacenter .item-3 ul li .position-2 .hengxian {
  width: 3.125vw;
  height: 0.104167vw;
  background: #ffffff;
  margin-bottom: 2.083333vw;
}
.datacenter .item-3 ul li .position-2 .item {
  margin-bottom: 1.302083vw;
}
.datacenter .item-3 ul li .position-3 {
  position: absolute;
  top: 2.34375vw;
  right: 6.770833vw;
}
.datacenter .item-3 ul li .position-3 img {
  width: 0.9375vw;
  height: 0.9375vw;
  margin-right: 0.260417vw;
}
.datacenter .item-3 ul li .position-3 .hengxian {
  width: 3.125vw;
  height: 0.104167vw;
  background: #ffffff;
  margin-bottom: 2.083333vw;
}
.datacenter .item-3 ul li .position-3 .list {
  display: flex;
}
.datacenter .item-3 ul li .position-3 .list .left div,
.datacenter .item-3 ul li .position-3 .list .right div {
  margin-bottom: 1.302083vw;
}
.datacenter .item-3 ul li .position-3 .list .left span,
.datacenter .item-3 ul li .position-3 .list .right span {
  margin-right: 4.427083vw;
}
.datacenter .item-3 .type {
  position: absolute;
  top: 79.166667vw;
  left: 8.333333vw;
}
.datacenter .item-3 .type p {
  margin: 0;
  padding: 0;
}
.datacenter .item-3 .type img {
  width: 7.8125vw;
  height: 3.125vw;
}
.datacenter .item-3 .type .top {
  margin-bottom: 4.84375vw;
  display: flex;
  margin-left: 8.333333vw;
}
.datacenter .item-3 .type .top div {
  margin-right: 4.6875vw;
}
.datacenter .item-3 .type .top div:hover {
  -webkit-transform: translateY(-0.416667vw);
  transform: translateY(-0.416667vw);
  transition: 0.3s;
}
.datacenter .item-3 .type .top div:hover {
  -webkit-transform: translateY(-0.416667vw);
  transform: translateY(-0.416667vw);
  transition: 0.3s;
}
.datacenter .item-3 .type .top div p {
  text-align: center;
}
.datacenter .item-3 .type .top div .item {
  position: relative;
}
.datacenter .item-3 .type .top div .item img {
  margin-left: 5.729167vw;
  margin-bottom: 1.979167vw;
}
.datacenter .item-3 .type .top div .item .text {
  position: absolute;
  font-family: Source Han Sans CN;
  top: 0.364583vw;
  left: 8.072917vw;
  font-size: 1.5625vw;
  color: #10e4f5;
}
.datacenter .item-3 .type .buttom {
  display: flex;
  justify-content: space-between;
  margin-left: -1.5625vw;
}
.datacenter .item-3 .type .buttom div {
  margin-right: 4.6875vw;
}
.datacenter .item-3 .type .buttom div:hover {
  -webkit-transform: translateY(-0.416667vw);
  transform: translateY(-0.416667vw);
  transition: 0.3s;
}
.datacenter .item-3 .type .buttom div p {
  text-align: center;
}
.datacenter .item-3 .type .buttom div .item {
  position: relative;
}
.datacenter .item-3 .type .buttom div .item img {
  margin-left: 5.729167vw;
  margin-bottom: 1.979167vw;
}
.datacenter .item-3 .type .buttom div .item .text {
  position: absolute;
  font-family: Source Han Sans CN;
  top: 0.364583vw;
  left: 8.072917vw;
  font-size: 1.5625vw;
  color: #10e4f5;
}
.datacenter .item-3 .type .buttom div .item .translate-1 {
  position: absolute;
  top: 0.364583vw;
  left: 7.604167vw;
}
.datacenter .item-3 .type .buttom div .item .translate-2 {
  position: absolute;
  top: 0.364583vw;
  left: 6.822917vw;
}
.datacenter .item-4 {
  position: relative;
}
.datacenter .item-4 .position {
  position: absolute;
  top: 13.541667vw;
  left: 5.208333vw;
}
.datacenter .item-4 .position > div {
  display: flex;
  margin-bottom: 17.708333vw;
}
.datacenter .item-4 .position > div:last-child {
  margin-bottom: 0;
}
.datacenter .item-4 .position .data-md {
  display: inline-block;
  /*   width: 100px;
        height: 2px;
        border: 1px solid red; */
  position: absolute;
  top: -12.5vw;
}
.datacenter .item-4 .position h4 {
  margin-bottom: 4.166667vw;
  font-size: 1.875vw;
  color: #fefeff;
}
.datacenter .item-4 .position .item {
  display: flex;
  margin-top: 2.083333vw;
}
.datacenter .item-4 .position .item span:first-child {
  margin-right: 2.604167vw;
}
.datacenter .item-4 .position .right {
  margin-left: 11.458333vw;
  margin-top: 0.78125vw;
  width: 31.25vw;
  height: 23.958333vw;
}
.datacenter .item-4 .position .left {
  margin-left: 1.5625vw;
  margin-right: 11.458333vw;
  margin-top: 0.78125vw;
  width: 31.25vw;
  height: 23.958333vw;
}
