.mobile-home .home-banner {
  width: 100%;
  height: 100vh;
  position: relative;
}
.mobile-home .home-banner .banner-pic {
  width: 100%;
  height: 100%;
  position: absolute;
}
.mobile-home .home-banner .banner-pic img {
  position: absolute;
  width: 100%;
  height: 100%;
}
.mobile-home .home-banner .big-banner {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  transition: 3s;
}
.mobile-home .slick-dots li button {
  background-color: #d98234 !important;
}
.mobile-home .home-intro {
  width: 100%;
  height: 100vh;
  background: #132233 url('@/assets/mbanner_4.png') no-repeat;
  background-size: 100% 100%;
  /* .m-modal-list {
      display: flex;
      margin-top: 153px;
      margin-bottom: 100px;
      margin-left: 50px;

      img {
        width: 100%;
        height: 296px;
        margin-right: 85px;
      }
    } */
}
.mobile-home .home-intro .intro-subtitle {
  font-size: 3.2vw;
  line-height: 10.133333vw;
  display: flex;
  align-items: center;
}
.mobile-home .home-intro .intro-subtitle .intro-subtitle-icon {
  width: 6.133333vw;
  height: 0.266667vw;
  background: linear-gradient(90deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
  opacity: 0.8;
  margin-right: 2.266667vw;
}
.mobile-home .home-intro .intro-subtitle .intro-subtitle-icon:nth-child(2) {
  margin-left: 2.266667vw;
}
.mobile-home .home-intro .intro-des {
  margin-top: 12vw;
  font-size: 3.733333vw;
  line-height: 6.4vw;
}
.mobile-home .m-modal-list {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: url(../../../assets/mbanner_4.png) no-repeat;
  background-size: 100%, 100%;
}
.mobile-home .m-modal-list .m-modal-list-in {
  margin-top: 6.666667vw;
  display: flex;
  flex: 1 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.mobile-home .m-modal-list .m-modal-list-in .m-modal-list-in-title {
  height: 4vw;
  font-size: 4vw;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 6.4vw;
  margin-top: 5.2vw;
}
.mobile-home .m-modal-list .m-modal-list-in .m-modal-list-in-icon {
  width: 10.666667vw;
  height: 10.666667vw;
  z-index: 10;
}
.mobile-home .m-modal-list .m-modal-list-in .m-modal-list-in-context {
  margin-top: 4.933333vw;
  padding: 0px 4vw;
  height: 13.333333vw;
  font-size: 2.133333vw;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #FFFFFF;
  text-align: center;
}
.mobile-home .m-modal-list .m-modal-list-in:nth-child(1) .m-modal-list-in-icon {
  background: url(../../../assets/icon/icon_shuju.png) no-repeat;
  background-size: 100%, 100%;
}
.mobile-home .m-modal-list .m-modal-list-in:nth-child(2) .m-modal-list-in-icon {
  background: url(../../../assets/icon/icon_suanfa.png) no-repeat;
  background-size: 100%, 100%;
}
.mobile-home .m-modal-list .m-modal-list-in:nth-child(3) .m-modal-list-in-icon {
  background: url(../../../assets/icon/icon_changjing.png) no-repeat;
  background-size: 100%, 100%;
}
.mobile-home .m-modal-list .m-modal-list-in:nth-child(4) .m-modal-list-in-icon {
  background: url(../../../assets/icon/icon_yunying.png) no-repeat;
  background-size: 100%, 100%;
}
.mobile-home .banner-content {
  position: absolute;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.mobile-home .banner-content span {
  color: #fff;
  text-align: center;
}
.mobile-home .banner-content .banner-title {
  font-size: 7.333333vw;
  line-height: 10.666667vw;
}
.mobile-home .banner-content .banner-subtitle {
  font-size: 3.2vw;
  margin-bottom: 7.333333vw;
  line-height: 6.4vw;
}
.mobile-home .banner-content .banner-des {
  font-size: 3.2vw;
  line-height: 6.4vw;
}
.mobile-home .banner-content .data-banner {
  margin-top: 10.4vw;
  width: 73.066667vw;
}
.mobile-home .home-cooper {
  width: 100%;
  height: 106.666667vw;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /*设置的子盒子高度大于父盒子，产生溢出效果*/
}
.mobile-home .home-cooper .cooper-title {
  font-size: 7.333333vw;
  color: #222;
  margin-bottom: 12.533333vw;
}
.mobile-home .home-cooper .cooper-parent {
  width: 100vw;
  padding: 0 4vw;
  overflow-x: scroll;
  display: flex;
  flex-direction: column;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.mobile-home .home-cooper .cooper-parent::-webkit-scrollbar {
  width: 0px;
}
.mobile-home .home-cooper .cooper-child {
  width: auto;
  display: flex;
  height: 10.666667vw;
  margin-bottom: 4vw;
}
.mobile-home .home-cooper .cooper-child img {
  height: 100%;
  width: 32vw;
  margin-right: 4vw;
  box-shadow: 0px 1.333333vw 2.4vw 0px rgba(225, 231, 236, 0.38);
}
.amodal0 .ant-modal-content .ant-modal-confirm-btns {
  margin-top: 3rem;
}
