.carAI {
  overflow: hidden;
}
.carAI .top {
  position: relative;
  /*  .big-name {
      animation: mybox 3s forwards;
    } */
}
.carAI .top img {
  width: 100%;
}
.carAI .top .text {
  position: absolute;
  width: 100%;
  top: 21.875vw;
  text-align: center;
}
.carAI .top .text h2 {
  font-size: 3.645833vw;
  font-weight: 400;
  color: #ffffff;
}
.carAI .top .text div {
  font-size: 1.302083vw;
  margin-top: -0.15625vw;
}
.carAI .buttom {
  position: relative;
}
.carAI .buttom img {
  width: 100%;
}
.carAI .buttom .item-1,
.carAI .buttom .item-2,
.carAI .buttom .item-3 {
  position: absolute;
  width: 100%;
}
.carAI .buttom .list-itemP {
  width: 100%;
  text-align: center;
  height: 2.96875vw;
  font-size: 3.125vw;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 3.125vw;
}
.carAI .buttom .content {
  width: 100%;
  padding-left: 7.8125vw;
  padding-right: 7.8125vw;
  margin-top: 3.177083vw;
  display: flex;
}
.carAI .buttom .content1,
.carAI .buttom .content2,
.carAI .buttom .content3 {
  flex: 1 1;
  width: 27.083333vw;
  height: 23.4375vw;
  text-align: center;
  /*  border: 1px solid red; */
}
.carAI .buttom .content1-top {
  width: 100%;
  height: 20.3125vw;
}
.carAI .buttom .content1-bot {
  width: 100%;
  height: 1.510417vw;
  font-size: 1.5625vw;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 3.125vw;
}
.carAI .buttom .list .item-1 {
  top: 10.729167vw;
}
.carAI .buttom .list .item-1 .content1 .content1-top {
  background: url(../../../assets/1111.png), no-repeat;
  background-size: 100% 100%;
}
.carAI .buttom .list .item-1 .content2 {
  margin: 0px 1.458333vw;
}
.carAI .buttom .list .item-1 .content2 .content1-top {
  background: url(../../../assets/2222.png), no-repeat;
  background-size: 100% 100%;
}
.carAI .buttom .list .item-1 .content3 .content1-top {
  background: url(../../../assets/3333.png), no-repeat;
  background-size: 100% 100%;
}
.carAI .buttom .list .item-2 {
  top: 53.125vw;
}
.carAI .buttom .list .item-2 .content1 .content1-top {
  background: url(../../../assets/4444.png), no-repeat;
  background-size: 100% 100%;
}
.carAI .buttom .list .item-2 .content2 {
  margin: 0px 1.458333vw;
}
.carAI .buttom .list .item-2 .content2 .content1-top {
  background: url(../../../assets/5555.png), no-repeat;
  background-size: 100% 100%;
}
.carAI .buttom .list .item-2 .content3 .content1-top {
  background: url(../../../assets/6666.png), no-repeat;
  background-size: 100% 100%;
}
.carAI .buttom .list .item-3 {
  top: 95.3125vw;
}
.carAI .buttom .list .item-3 .content1 .content1-top {
  background: url(../../../assets/7777.png), no-repeat;
  background-size: 100% 100%;
}
.carAI .buttom .list .item-3 .content2 {
  margin: 0px 1.458333vw;
}
.carAI .buttom .list .item-3 .content2 .content1-top {
  background: url(../../../assets/8888.png), no-repeat;
  background-size: 100% 100%;
}
.carAI .buttom .list .item-3 .content3 .content1-top {
  background: url(../../../assets/9999.png), no-repeat;
  background-size: 100% 100%;
}
@-webkit-keyframes mybox {
  form {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  to {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }
}
@keyframes mybox {
  form {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  to {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }
}
