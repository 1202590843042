@-webkit-keyframes mybox {
  form {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  to {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }
}
@keyframes mybox {
  form {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  to {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }
}
.mobile-saleengine .top {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mobile-saleengine .top img {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
}
.mobile-saleengine .top .big-name {
  -webkit-animation: mybox 3s forwards;
  animation: mybox 3s forwards;
}
.mobile-saleengine .top .text {
  text-align: center;
}
.mobile-saleengine .top .text div:nth-child(1) {
  font-size: 7.333333vw;
  font-weight: 400;
  color: #ffffff;
  margin-bottom: 10.133333vw;
}
.mobile-saleengine .top .text div {
  font-size: 3.733333vw;
}
.mobile-saleengine .center {
  width: 100%;
  height: 77.333333vw;
  background: url('@/assets/big2.jpg') no-repeat;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.mobile-saleengine .center img {
  width: 112%;
  height: 72.266667vw;
}
.mobile-saleengine .buttom {
  width: 100%;
  height: 266.666667vw;
  background: url('@/assets/salebg2.png') no-repeat;
  background-size: 100% 100%;
}
.mobile-saleengine .buttom .list {
  width: 100%;
  padding-top: 100.8vw;
  padding-left: 8vw;
}
.mobile-saleengine .buttom .list .list-item {
  width: 100%;
  position: relative;
  margin-bottom: 5.066667vw;
}
.mobile-saleengine .buttom .list .list-item img {
  width: 48.533333vw;
  height: 14.666667vw;
  position: absolute;
  top: 0;
  left: 0;
}
.mobile-saleengine .buttom .list .list-item h3 {
  width: 53.333333vw;
  text-align: center;
  line-height: 8vw;
  font-size: 4vw;
  color: #ffffff;
  font-weight: 400;
  margin-bottom: 0;
}
.mobile-saleengine .buttom .list .list-item .text {
  margin-left: 9.2vw;
  margin-top: 3.2vw;
}
.mobile-saleengine .buttom .list .list-item .text p {
  font-size: 3.2vw;
  color: #C4C6CC;
  line-height: 4.8vw;
  margin-bottom: 0;
}
