div {
  margin: 0;
  padding: 0;
}
ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
ul li {
  margin: 0;
  padding: 0;
}
@-webkit-keyframes mybox {
  form {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  to {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }
}
@keyframes mybox {
  form {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  to {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }
}
.mobile-datacenter .item-1 {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.mobile-datacenter .item-1 img {
  position: absolute;
  width: 100%;
  height: 100%;
}
.mobile-datacenter .item-1 .title {
  font-size: 7.333333vw;
  margin-bottom: 8.266667vw;
}
.mobile-datacenter .item-1 span {
  font-size: 3.733333vw;
  line-height: 8vw;
}
.mobile-datacenter .item-1 .data-list {
  width: 100%;
  height: 14.4vw;
  background: #03529C;
  box-shadow: 0px 0.266667vw 2.133333vw 0px rgba(0, 23, 45, 0.3);
  opacity: 0.8;
  position: absolute;
  bottom: 0;
}
.mobile-datacenter .item-1 .data-list .ant-tabs-nav {
  border-bottom: 0 !important;
  margin: 0;
}
.mobile-datacenter .item-1 .data-list .ant-tabs-nav .ant-tabs-tab {
  padding: 0;
  margin: 0;
}
.mobile-datacenter .item-1 .data-list .ant-tabs-nav .ant-tabs-tab-active div {
  color: #FFFFFF;
}
.mobile-datacenter .item-1 .data-list .ant-tabs-nav::before {
  border-bottom: 0 !important;
}
.mobile-datacenter .item-1 .data-list .banner-tabs {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 23.466667vw;
  height: 13.866667vw;
}
.mobile-datacenter .item-1 .data-list .banner-tabs div {
  text-align: center;
  font-size: 2.933333vw;
  color: #C3DFFA;
  font-family: Arial;
}
.mobile-datacenter .item-2 {
  width: 100%;
  height: 93.333333vw;
  background: url('@/assets/bg2.jpg') no-repeat;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
}
.mobile-datacenter .item-2 .title {
  width: 100%;
  text-align: center;
  font-size: 4.8vw;
  margin-top: 10.666667vw;
  margin-bottom: 8vw;
}
.mobile-datacenter .item-2 .postion::-webkit-scrollbar {
  width: 0px;
}
.mobile-datacenter .item-2 .postion {
  position: relative;
  width: 100%;
  height: 58.933333vw;
  overflow-x: scroll;
  overflow-y: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.mobile-datacenter .item-2 .postion .banner-item {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 33.333333vw;
  width: 22.266667vw;
  position: absolute;
}
.mobile-datacenter .item-2 .postion .banner-item img {
  position: absolute;
  height: 100%;
  width: 100%;
}
.mobile-datacenter .item-2 .postion .banner-item ul {
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
}
.mobile-datacenter .item-2 .postion .banner-item ul li {
  display: block;
  color: white;
  width: 18.666667vw;
  height: 5.866667vw;
  border: 0.266667vw solid #1255ED;
  border-radius: 0.533333vw;
  font-size: 2.666667vw;
  line-height: 5.866667vw;
  text-align: center;
  margin-top: 1.6vw;
}
.mobile-datacenter .item-2 .postion .syllsj {
  top: 0;
  left: 4vw;
}
.mobile-datacenter .item-2 .postion .bqhx {
  top: 0;
  left: 36.133333vw;
  height: 27.333333vw;
}
.mobile-datacenter .item-2 .postion .yfsj {
  width: 54.666667vw;
  height: 21.6vw;
  bottom: 0;
  left: 4vw;
  display: flex;
}
.mobile-datacenter .item-2 .postion .yfsj ul {
  width: 66.666667vw;
  height: 26.666667vw;
  padding: 6.933333vw 4.266667vw;
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.mobile-datacenter .item-2 .postion .yfsj ul li {
  display: inline-block;
  width: 17.333333vw;
  height: 5.866667vw;
  line-height: 5.866667vw;
  flex-shrink: 0;
}
.mobile-datacenter .item-2 .postion .sjzt {
  top: 15.6vw;
  left: 71.066667vw;
  height: 27.733333vw;
}
.mobile-datacenter .item-2 .postion .sjzt li {
  width: 24vw !important;
}
.mobile-datacenter .item-2 .postion .yxzdh {
  height: 26.666667vw;
  top: 0;
  left: 103.2vw;
}
.mobile-datacenter .item-2 .postion .yxzdh li {
  width: 21.333333vw !important;
}
.mobile-datacenter .item-2 .postion .ksh {
  height: 26.666667vw;
  bottom: 0;
  left: 103.2vw;
}
.mobile-datacenter .item-2 .postion .jw {
  width: 22.266667vw;
  height: 56.666667vw;
  top: 2.266667vw;
  left: 139.333333vw;
  padding-right: 8.8vw;
}
.mobile-datacenter .item-2 .postion .jw ul li {
  width: 18.666667vw !important;
  height: 8.266667vw !important;
  font-size: 3.333333vw;
  line-height: 8.266667vw;
  margin-top: 8.266667vw !important;
  background: linear-gradient(90deg, #12B2ED, #0930C5);
  border-radius: 0.533333vw;
  color: #FFFFFF;
}
.mobile-datacenter .item-2 .postion .jw ul li:nth-child(1) {
  margin-top: 0 !important;
}
.mobile-datacenter .item-2 .jt {
  position: absolute;
  width: 5.6vw;
  height: 4.133333vw;
}
.mobile-datacenter .item-2 .jt1 {
  top: 11.866667vw;
  left: 29.333333vw;
}
.mobile-datacenter .item-2 .jt2 {
  top: 19.333333vw;
  left: 63.466667vw;
}
.mobile-datacenter .item-2 .jt3 {
  bottom: 17.066667vw;
  left: 63.466667vw;
}
.mobile-datacenter .item-2 .jt4 {
  top: 19.333333vw;
  left: 95.733333vw;
}
.mobile-datacenter .item-2 .jt5 {
  bottom: 17.066667vw;
  left: 95.733333vw;
}
.mobile-datacenter .item-2 .jt6 {
  top: 19.333333vw;
  left: 127.6vw;
}
.mobile-datacenter .item-2 .jt7 {
  bottom: 17.066667vw;
  left: 127.6vw;
}
.mobile-datacenter .item-3 {
  width: 100%;
  background: url("@/assets/databg3.png") no-repeat;
  background-size: 100% 100%;
  padding: 0 5.333333vw;
  padding-bottom: 8vw;
}
.mobile-datacenter .item-3 .title {
  width: 100%;
  font-size: 4.8vw;
  color: #ffffff;
  padding: 10.666667vw 8vw;
  text-align: center;
}
.mobile-datacenter .item-3 .data-item {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: url("@/assets/xk1.png") no-repeat;
  background-size: 100% 100%;
  padding: 6.666667vw;
  margin-bottom: 4vw;
}
.mobile-datacenter .item-3 .data-item .data-item-title {
  font-size: 4vw;
  margin-bottom: 2.666667vw;
}
.mobile-datacenter .item-3 .data-item .hengxian {
  width: 8vw;
  height: 0.266667vw;
  background: #FFFFFF;
  margin-bottom: 4.8vw;
}
.mobile-datacenter .item-3 .data-item .data-item-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.mobile-datacenter .item-3 .data-item .data-item-list .item img {
  width: 3.466667vw;
  height: 3.466667vw;
  margin-right: 1.866667vw;
}
.mobile-datacenter .item-3 .data-item .data-item-list .item span {
  font-size: 3.2vw;
}
.mobile-datacenter .item-3 .data-item .industry-list .item {
  width: 33.33%;
}
.mobile-datacenter .item-3 .type {
  display: flex;
  flex-direction: column;
  margin-top: 83.466667vw;
}
.mobile-datacenter .item-3 .type .type-item {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 6.666667vw;
}
.mobile-datacenter .item-3 .type .type-item .item {
  width: 20.933333vw;
  height: 8.533333vw;
  line-height: 8.533333vw;
  text-align: center;
  background: url("@/assets/xk2.png") no-repeat;
  background-size: 100% 100%;
  margin-bottom: 3.066667vw;
}
.mobile-datacenter .item-3 .type .type-item .item .text {
  font-size: 4vw;
  color: #00EAFF;
}
.mobile-datacenter .item-3 .type .type-item p {
  font-size: 3.2vw;
  line-height: 4.8vw;
  margin-bottom: 0;
}
.mobile-datacenter .item-4 {
  display: flex;
  flex-direction: column;
  background: url('@/assets/databg4.png') no-repeat;
  background-size: 100% 100%;
  padding: 11.466667vw 0;
}
.mobile-datacenter .item-4 .item-4-item {
  margin-bottom: 7.2vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.mobile-datacenter .item-4 .item-4-item img {
  width: 82.666667vw;
  height: 56.666667vw;
  margin-bottom: 4.666667vw;
}
.mobile-datacenter .item-4 .item-4-item .item-4-title {
  width: 100%;
  height: 8vw;
  display: flex;
  align-items: baseline;
  padding: 0 2.133333vw;
  margin-bottom: 6.4vw;
}
.mobile-datacenter .item-4 .item-4-item .item-4-title img {
  width: 6.666667vw;
  height: 8vw;
  margin-bottom: 0;
}
.mobile-datacenter .item-4 .item-4-item .item-4-title span {
  font-size: 4.8vw;
  color: #FEFEFF;
  margin-left: -3.333333vw;
}
.mobile-datacenter .item-4 .item-4-item h4 {
  font-size: 4.8vw;
  color: #FEFEFF;
  margin-bottom: 6.4vw;
}
.mobile-datacenter .item-4 .item-4-item .item {
  display: flex;
  flex-direction: column;
  margin-bottom: 5.866667vw;
  padding: 0 5.333333vw;
  width: 100%;
}
.mobile-datacenter .item-4 .item-4-item .item span:nth-child(1) {
  font-size: 4vw;
  color: #FEFEFF;
  margin-bottom: 2.533333vw;
}
.mobile-datacenter .item-4 .item-4-item .item span:nth-child(2) {
  font-size: 3.2vw;
  color: #C4C6CC;
  line-height: 5.333333vw;
  text-align: justify;
}
.mobile-datacenter .item-4 .item-4-item:last-child {
  margin-bottom: 0;
}
