.mobile-dealengine .deal-banner {
  position: relative;
  width: 100%;
  height: 100vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.mobile-dealengine .deal-banner img {
  position: absolute;
  width: 100%;
  height: 100%;
}
.mobile-dealengine .deal-banner span {
  font-size: 3.733333vw;
  line-height: 8vw;
  text-align: center;
}
.mobile-dealengine .deal-banner .deal-baner-title {
  margin-top: 30.666667vw;
  font-size: 7.333333vw;
  margin-bottom: 8.266667vw;
}
.mobile-dealengine .deal-intro {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 10.666667vw 4vw;
  width: 100%;
  height: 189.333333vw;
  background: #132233 url(@/assets/dealbg2.png) no-repeat;
  background-size: 100% 100%;
}
.mobile-dealengine .deal-intro .intro-banner {
  margin-top: 7.2vw;
  height: 67.333333vw;
  display: flex;
  margin-right: 12.8vw;
}
.mobile-dealengine .deal-intro .intro-banner .intro-banner-left {
  margin-right: 2.133333vw;
}
.mobile-dealengine .deal-intro .intro-banner .intro-banner-left img {
  width: 33.866667vw;
  height: 100%;
}
.mobile-dealengine .deal-intro .intro-banner .intro-banner-right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.mobile-dealengine .deal-intro .intro-banner .intro-banner-right img {
  width: 56vw;
  height: 32.533333vw;
}
.mobile-dealengine .deal-intro .intro-info {
  display: flex;
  flex-direction: column;
}
.mobile-dealengine .deal-intro .intro-info .first-title {
  font-size: 4.8vw;
  text-align: center;
  margin-bottom: 8vw;
}
.mobile-dealengine .deal-intro .intro-info .sub-title {
  font-size: 4vw;
  margin-bottom: 2.133333vw;
}
.mobile-dealengine .deal-intro .intro-info .info-desc {
  font-size: 3.2vw;
  color: #C4C6CC;
  line-height: 4.8vw;
  margin-bottom: 5.866667vw;
}
.mobile-dealengine .deal-modal {
  width: 100%;
  background: #132233 url(@/assets/mobilebg3.png) no-repeat;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
}
.mobile-dealengine .deal-modal .deal-modal-title {
  font-size: 4.8vw;
  color: #FFFFFF;
  line-height: 6.4vw;
  text-align: center;
  margin: 9.6vw 8.266667vw;
}
.mobile-dealengine .deal-modal .deal-modal-list {
  position: relative;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding-left: 11.466667vw;
}
.mobile-dealengine .deal-modal .deal-modal-list .deal-modal-item {
  display: flex;
  margin-bottom: 7.333333vw;
}
.mobile-dealengine .deal-modal .deal-modal-list .deal-modal-item span:nth-child(1) {
  width: 24vw;
  height: 8vw;
  line-height: 8vw;
  font-size: 4vw;
  border: 0.266667vw solid #3CA1FF;
  text-align: center;
  margin-right: 4vw;
}
.mobile-dealengine .deal-modal .deal-modal-list .deal-modal-item span:nth-child(2) {
  height: 14.4vw;
  display: flex;
  justify-content: center;
  font-size: 3.2vw;
  margin-top: -0.533333vw;
  color: #C4C6CC;
}
.mobile-dealengine .deal-modal .deal-modal-list .deal-modal-item:last-child {
  margin-bottom: 97.333333vw;
}
