@-webkit-keyframes mybox {
  form {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  to {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }
}
@keyframes mybox {
  form {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  to {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }
}
html {
  scroll-behavior: smooth;
}
.case .banner-1 {
  position: relative;
  overflow: hidden;
}
.case .banner-1 img {
  width: 100%;
  height: 100%;
}
.case .banner-1 .big-img {
  -webkit-animation: mybox 3s forwards;
  animation: mybox 3s forwards;
}
.case .banner-1 .text {
  position: absolute;
  top: 22.916667vw;
  left: 32.03125vw;
}
.case .banner-1 .text h2 {
  text-align: center;
  font-size: 3.125vw;
  color: #ffffff;
  font-weight: 400;
  text-shadow: 0px 0px 0.9375vw rgba(0, 16, 57, 0.67);
}
.case .banner-1 .text .mini-h2 {
  font-size: 1.5625vw;
}
.case .banner-2 {
  position: relative;
}
.case .banner-2 img {
  width: 100%;
  height: 100%;
}
.case .banner-2 .app-list {
  display: flex;
  flex-wrap: wrap;
  position: absolute;
  top: 11.458333vw;
  left: 9.895833vw;
}
.case .banner-2 .app-list .app-item {
  margin-bottom: 1.5625vw;
  margin-right: 1.5625vw;
}
.case .banner-2 .app-list .app-item .top {
  width: 25.520833vw;
  height: 19.270833vw;
}
.case .banner-2 .app-list .app-item .buttom {
  padding: 0.9375vw 1.302083vw 0.9375vw 1.302083vw;
  background-color: #fff;
  width: 25.520833vw;
}
.case .banner-2 .app-list .app-item .buttom h4 {
  font-weight: 400;
  font-family: Source Han Sans CN;
  font-size: 1.770833vw;
  color: #353535;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0.260417vw;
}
.case .banner-2 .app-list .app-item .buttom p {
  color: #636466;
  font-size: 0.9375vw;
  width: 100%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.case .banner-2 .app-list .app-item .buttom .footer {
  display: flex;
  justify-content: space-between;
}
.case .banner-2 .app-list .app-item .buttom .footer span {
  color: #949699;
  line-height: 2.604167vw;
}
.case .banner-2 .app-list .app-item .buttom .footer .botton {
  cursor: pointer;
  width: 7.552083vw;
  height: 2.604167vw;
  border: 0.104167vw solid #1154ed;
  display: flex;
  padding: 0.416667vw;
  justify-content: space-around;
}
.case .banner-2 .app-list .app-item .buttom .footer .botton div {
  color: #1154ed;
  font-size: 1.041667vw;
}
.case .banner-2 .app-list .app-item .buttom .footer .botton img {
  width: 1.25vw;
  height: 0.416667vw;
  margin-top: 0.625vw;
}
.case .banner-2 .app-list .app-item .buttom .footer .botton:hover {
  background-color: skyblue;
}
.case .banner-2 .app-list .app-item:hover {
  transition: all 0.3s;
  -webkit-transform: translateY(-0.520833vw);
  transform: translateY(-0.520833vw);
  cursor: pointer;
}
