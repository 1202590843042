.home .lefticon {
  z-index: 999;
  position: absolute;
  width: 2.604167vw;
  height: 3.645833vw;
  top: 5.208333vw;
  left: 0.520833vw;
  border: 1px solid red;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.5;
}
.home .righticon {
  z-index: 999;
  position: absolute;
  width: 2.604167vw;
  height: 3.645833vw;
  top: 5.208333vw;
  left: 5.208333vw;
  border: 1px solid red;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home .ant-carousel .slick-prev,
.home .ant-carousel .slick-prev:hover,
.home .ant-carousel .slick-prev:focus {
  font-size: 2.604167vw;
  left: 2.604167vw;
  z-index: 2;
  color: #c98244;
}
.home .ant-carousel .slick-next,
.home .ant-carousel .slick-next:hover,
.home .ant-carousel .slick-next:focus {
  font-size: 2.604167vw;
  right: 3.125vw;
  z-index: 2;
  color: #c98244;
}
.home .home-banner {
  width: 100%;
  height: 100vh;
  position: relative;
}
.home .home-banner .banner-pic {
  width: 100%;
  height: 100%;
  background-color: #132233;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
}
.home .home-banner .big-banner {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  transition: 3s;
}
.home .slick-dots li button {
  background-color: #d98234 !important;
}
.home .home-intro {
  width: 100%;
  height: 100vh;
  background: #132233 url('@/assets/banner_4.png') no-repeat;
  background-size: 100% 100%;
}
.home .home-intro .intro-subtitle {
  font-size: 2.083333vw;
  line-height: 5.208333vw;
  display: flex;
  align-items: center;
}
.home .home-intro .intro-subtitle .intro-subtitle-icon {
  width: 4.0625vw;
  height: 0.208333vw;
  background: linear-gradient(90deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
  opacity: 0.8;
  margin-right: 0.729167vw;
}
.home .home-intro .intro-subtitle .intro-subtitle-icon:nth-child(2) {
  margin-left: 0.729167vw;
}
.home .home-intro .modal-list {
  display: flex;
  width: 80%;
  margin-top: 7.96875vw;
  align-items: center;
  justify-content: center;
}
.home .home-intro .modal-list .modal-list-in {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.home .home-intro .modal-list .modal-list-in .modal-list-in-title {
  height: 1.5625vw;
  font-size: 1.5625vw;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 2.5vw;
  margin-top: 2.03125vw;
}
.home .home-intro .modal-list .modal-list-in .modal-list-in-icon {
  width: 4.166667vw;
  height: 4.166667vw;
  z-index: 10;
}
.home .home-intro .modal-list .modal-list-in .modal-list-in-context {
  margin-top: 1.927083vw;
  padding: 0px 1.5625vw;
  height: 5.208333vw;
  font-size: 0.833333vw;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #FFFFFF;
}
.home .home-intro .modal-list .modal-list-in:nth-child(1) .modal-list-in-icon {
  background: url(../../assets/icon/icon_shuju.png) no-repeat;
  background-size: 100%, 100%;
}
.home .home-intro .modal-list .modal-list-in:nth-child(2) .modal-list-in-icon {
  background: url(../../assets/icon/icon_suanfa.png) no-repeat;
  background-size: 100%, 100%;
}
.home .home-intro .modal-list .modal-list-in:nth-child(3) .modal-list-in-icon {
  background: url(../../assets/icon/icon_changjing.png) no-repeat;
  background-size: 100%, 100%;
}
.home .home-intro .modal-list .modal-list-in:nth-child(4) .modal-list-in-icon {
  background: url(../../assets/icon/icon_yunying.png) no-repeat;
  background-size: 100%, 100%;
}
.home .banner-content {
  position: absolute;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.home .banner-content span {
  color: #fff;
  text-align: center;
}
.home .banner-content .banner-title {
  font-size: 3.75vw;
  margin-bottom: 0.833333vw;
}
.home .banner-content .banner-subtitle {
  font-size: 1.25vw;
  margin-bottom: 1.875vw;
  line-height: 2.5vw;
}
.home .banner-content .banner-des {
  font-size: 1.25vw;
  line-height: 2.5vw;
}
.home .banner-content .banner-tab {
  margin-top: 0.885417vw;
}
.home .banner-content .banner-tab .tab-top {
  display: flex;
  margin-bottom: 1.041667vw;
}
.home .banner-content .banner-tab .tab-bottom {
  display: flex;
  justify-content: center;
}
.home .banner-content .banner-tab span:first-child {
  display: inline-block;
  width: 6.25vw;
  line-height: 2.8125vw;
  color: #133363;
  font-size: 1.458333vw;
  background: #70d9fc;
  margin-left: 1.041667vw;
}
.home .banner-content .banner-tab span:nth-child(2) {
  display: inline-block;
  width: 7.03125vw;
  line-height: 2.291667vw;
  border: 1px solid #70d9fc;
  border-left: none;
  font-size: 0.9375vw;
  color: #70d9fc;
}
.home .home-cooper {
  width: 100%;
  height: 100vh;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /*设置的子盒子高度大于父盒子，产生溢出效果*/
}
.home .home-cooper .cooper-title {
  font-size: 3.75vw;
  color: #222;
  margin-bottom: 6.510417vw;
}
.home .home-cooper .cooper-parent {
  width: 98vw;
  height: 6.770833vw;
  overflow: hidden;
  display: flex;
  margin-bottom: 1.770833vw;
}
.home .home-cooper .cooper-child {
  width: auto;
  display: flex;
}
.home .home-cooper .cooper-child img {
  height: 100%;
  width: 19.479167vw;
  margin-right: 2.291667vw;
}
.nav-list div {
  width: 2.604167vw;
  height: 2.604167vw;
  border-radius: 0.3125vw;
}
.amodal .ant-modal-content .ant-modal-confirm-btns {
  padding-right: 1.145833vw;
}
