@-webkit-keyframes vibilbox {
  form {
    opacity: 1;
    visibility: visible;
  }
  to {
    opacity: 0;
  }
}
@keyframes vibilbox {
  form {
    opacity: 1;
    visibility: visible;
  }
  to {
    opacity: 0;
  }
}
.footer-box {
  height: 46.875vw;
  width: 100%;
  background: #132233 url('@/assets/footer.png') no-repeat;
  padding-top: 10.416667vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.footer-box .top {
  margin-left: 8.333333vw;
  margin-right: 7.291667vw;
  display: flex;
  justify-content: space-between;
}
.footer-box .top .left h3 {
  font-size: 3.489583vw;
  color: #fff;
}
.footer-box .top .left .line {
  border-bottom: 1px solid #fff;
  width: 13.802083vw;
  margin-top: -2.083333vw;
  margin-bottom: 3.958333vw;
}
.footer-box .top .left .text .text-item {
  display: flex;
  margin-bottom: 0.885417vw;
}
.footer-box .top .left .text .text-item img {
  margin-top: 0.46875vw;
  width: 0.9375vw;
  height: 0.885417vw;
  margin-right: 1.5625vw;
}
.footer-box .top .left .text .text-item span {
  font-size: 1.041667vw;
}
.footer-box .top .right {
  width: 50%;
}
.footer-box .top .right .ant-select {
  width: 100%;
}
.footer-box .top .right .ant-select .ant-select-selector {
  border: none !important;
  width: 100%;
  height: 3.125vw;
  background: rgba(50, 64, 81, 0.7);
  padding-right: 1.041667vw;
  margin-bottom: 1.041667vw;
}
.footer-box .top .right .ant-select .ant-select-selector::-webkit-input-placeholder {
  color: #bcbcbc;
}
.footer-box .top .right .ant-select .ant-select-selector::placeholder {
  color: #bcbcbc;
}
.footer-box .top .right .ant-select .ant-select-arrow {
  margin-top: -0.78125vw;
  color: #bcbcbc;
}
.footer-box .top .right .ant-select .ant-select-selection-item {
  color: #bcbcbc;
  margin-top: 0.625vw;
  margin-left: 0.208333vw;
  font-size: 0.833333vw;
}
.footer-box .top .right .ant-select .ant-select-selection-placeholder {
  margin-top: 0.625vw;
  margin-left: 0.208333vw;
  font-size: 0.833333vw;
}
.footer-box .top .right .right-flex {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.footer-box .top .right .right-flex input {
  width: 20.052083vw;
  height: 3.125vw;
  background: rgba(50, 64, 81, 0.7);
  margin-bottom: 1.041667vw;
  padding-left: 1.041667vw;
  outline: none;
  border: none;
}
.footer-box .top .right .right-flex input::-webkit-input-placeholder {
  color: #bcbcbc;
}
.footer-box .top .right .right-flex input::placeholder {
  color: #bcbcbc;
}
.footer-box .top .right .right-flex input.active {
  width: 20.052083vw;
  height: 3.125vw;
  background: rgba(50, 64, 81, 0.7);
  margin-bottom: 1.041667vw;
  padding-left: 1.041667vw;
  outline: none;
  border: none;
}
.footer-box .top .right .right-flex input.active::-webkit-input-placeholder {
  color: red;
}
.footer-box .top .right .right-flex input.active::placeholder {
  color: red;
}
.footer-box .top .right .right-flex input.text-red {
  color: red;
}
.footer-box .top .right .big-input {
  padding-top: 0.677083vw;
  width: 100%;
  height: 5.208333vw;
  background: rgba(50, 64, 81, 0.7);
  outline: none;
  padding-left: 1.041667vw;
  border: none;
}
.footer-box .top .right .big-input::-webkit-input-placeholder {
  color: #bcbcbc;
  margin-top: 0.15625vw;
}
.footer-box .top .right .big-input::placeholder {
  color: #bcbcbc;
  margin-top: 0.15625vw;
}
.footer-box .top .right .submit-box {
  line-height: 5.208333vw;
  font-size: 1.041667vw;
}
.footer-box .top .right .submit-box p {
  float: left;
  visibility: visible;
  margin-right: 1.041667vw;
}
.footer-box .top .right .submit-box .submit {
  font-size: 1.041667vw;
  display: flex;
  width: 7.8125vw;
  height: 3.125vw;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: #d98234;
  border: none;
  float: right;
  margin-top: 1.041667vw;
}
.footer-box .buttom {
  font-size: 0.989583vw;
  width: 100%;
  height: 6.770833vw;
  border-top: 1px solid #76635b;
  line-height: 6.770833vw;
  text-align: center;
  font-family: Arial;
}
.footer-box .buttom span {
  cursor: pointer;
  text-decoration: underline;
}
