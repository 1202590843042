.nav-list {
  z-index: 999;
  position: fixed;
  display: flex;
  flex-direction: column;
  bottom: 5.729167vw;
  right: 2.604167vw;
}
.nav-list div {
  width: 2.604167vw;
  height: 2.604167vw;
  background-color: #fff;
  margin-bottom: 0.78125vw;
  border-radius: 0.3125vw;
  cursor: pointer;
}
.nav-list div:hover {
  background-color: #d98234;
}
.nav-list .nav-item-1 {
  display: flex;
  justify-content: center;
  align-items: center;
}
.nav-list .nav-item-1 img {
  width: 1.25vw;
  height: 1.25vw;
}
.nav-list .nav-item-2 {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.nav-list .nav-item-2 .tp {
  height: 1.302083vw;
  width: 1.302083vw;
}
.nav-list .nav-item-2 .qp {
  position: absolute;
  top: -4.947917vw;
  left: -8.854167vw;
  width: 8.59375vw;
  height: 9.375vw;
  display: none;
}
.nav-list .nav-item-2 .pic {
  position: absolute;
  top: -4.427083vw;
  left: -8.229167vw;
  display: none;
}
.nav-list .nav-item-2 .pic img {
  width: 7.03125vw;
  height: 7.03125vw;
}
.nav-list .nav-item-2 .pic p {
  font-size: 0.729167vw;
  color: #666666;
  width: 26.041667vw;
  position: relative;
  top: 0px;
  left: 0.729167vw;
}
.nav-list .nav-item-3 {
  display: flex;
  justify-content: center;
  align-items: center;
}
.nav-list .nav-item-3 .tp {
  height: 1.302083vw;
  width: 1.302083vw;
}
.nav-list .nav-item-3 .qp {
  position: absolute;
  top: 1.822917vw;
  left: -8.854167vw;
  width: 8.59375vw;
  height: 9.375vw;
  display: none;
}
.nav-list .nav-item-3 .pic {
  position: absolute;
  top: 2.34375vw;
  left: -8.229167vw;
  display: none;
}
.nav-list .nav-item-3 .pic img {
  width: 7.03125vw;
  height: 7.03125vw;
}
.nav-list .nav-item-3 .pic p {
  font-size: 0.729167vw;
  color: #666666;
  width: 26.041667vw;
  position: relative;
  top: 0px;
  left: 0.78125vw;
}
