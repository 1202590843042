.m-annotationall {
  overflow: hidden;
}
.m-annotationall .m-annotation-top .m-annotation-banner,
.m-annotationall .m-annotation-top .m-annotation-banner1,
.m-annotationall .m-annotation-top .m-annotation-banner2,
.m-annotationall .m-annotation-top .m-annotation-banner3,
.m-annotationall .m-annotation-top .m-annotation-banner4,
.m-annotationall .m-annotation-top .m-annotation-banner5 {
  position: relative;
}
.m-annotationall .m-annotation-top .m-annotation-banner img,
.m-annotationall .m-annotation-top .m-annotation-banner1 img,
.m-annotationall .m-annotation-top .m-annotation-banner2 img,
.m-annotationall .m-annotation-top .m-annotation-banner3 img,
.m-annotationall .m-annotation-top .m-annotation-banner4 img,
.m-annotationall .m-annotation-top .m-annotation-banner5 img {
  width: 100%;
  height: 100%;
}
.m-annotationall .m-annotation-top .m-annotation-banner .maodian,
.m-annotationall .m-annotation-top .m-annotation-banner1 .maodian,
.m-annotationall .m-annotation-top .m-annotation-banner2 .maodian,
.m-annotationall .m-annotation-top .m-annotation-banner3 .maodian,
.m-annotationall .m-annotation-top .m-annotation-banner4 .maodian,
.m-annotationall .m-annotation-top .m-annotation-banner5 .maodian {
  position: absolute;
  top: -13.333333vw;
  left: 0px;
}
.m-annotationall .m-annotation-top .m-annotation-banner .m-annotation-baner-title,
.m-annotationall .m-annotation-top .m-annotation-banner1 .m-annotation-baner-title,
.m-annotationall .m-annotation-top .m-annotation-banner2 .m-annotation-baner-title,
.m-annotationall .m-annotation-top .m-annotation-banner3 .m-annotation-baner-title,
.m-annotationall .m-annotation-top .m-annotation-banner4 .m-annotation-baner-title,
.m-annotationall .m-annotation-top .m-annotation-banner5 .m-annotation-baner-title {
  position: absolute;
  top: 15.866667vw;
  width: 100%;
  height: 8vw;
  font-size: 4.8vw;
  font-family: SourceHanSansCN-Normal;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 8vw;
  text-align: center;
}
.m-annotationall .m-annotation-top .m-annotation-banner .m-annotation-baner-txt,
.m-annotationall .m-annotation-top .m-annotation-banner1 .m-annotation-baner-txt,
.m-annotationall .m-annotation-top .m-annotation-banner2 .m-annotation-baner-txt,
.m-annotationall .m-annotation-top .m-annotation-banner3 .m-annotation-baner-txt,
.m-annotationall .m-annotation-top .m-annotation-banner4 .m-annotation-baner-txt,
.m-annotationall .m-annotation-top .m-annotation-banner5 .m-annotation-baner-txt {
  position: absolute;
  top: 40.133333vw;
  margin-left: 4vw;
  width: 100%;
  font-size: 3.733333vw;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 6.4vw;
  text-align: left;
}
.m-annotationall .m-annotation-top .m-annotation-banner .m-annotation-baner-tag,
.m-annotationall .m-annotation-top .m-annotation-banner1 .m-annotation-baner-tag,
.m-annotationall .m-annotation-top .m-annotation-banner2 .m-annotation-baner-tag,
.m-annotationall .m-annotation-top .m-annotation-banner3 .m-annotation-baner-tag,
.m-annotationall .m-annotation-top .m-annotation-banner4 .m-annotation-baner-tag,
.m-annotationall .m-annotation-top .m-annotation-banner5 .m-annotation-baner-tag {
  position: absolute;
  width: 100vw;
  height: 6.666667vw;
  top: 28.4vw;
}
.m-annotationall .m-annotation-top .m-annotation-banner .m-annotation-baner-tag .m-annotation-baner-intag,
.m-annotationall .m-annotation-top .m-annotation-banner1 .m-annotation-baner-tag .m-annotation-baner-intag,
.m-annotationall .m-annotation-top .m-annotation-banner2 .m-annotation-baner-tag .m-annotation-baner-intag,
.m-annotationall .m-annotation-top .m-annotation-banner3 .m-annotation-baner-tag .m-annotation-baner-intag,
.m-annotationall .m-annotation-top .m-annotation-banner4 .m-annotation-baner-tag .m-annotation-baner-intag,
.m-annotationall .m-annotation-top .m-annotation-banner5 .m-annotation-baner-tag .m-annotation-baner-intag {
  display: inline-block;
  margin: 0px 2vw;
  border: 1px solid #70E3FC;
  font-size: 3.2vw;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #70E3FC;
  line-height: 8vw;
  text-align: center;
}
.m-annotationall .m-annotation-top .m-annotation-banner .m-annotation-baner-title {
  font-size: 7.333333vw;
  top: 43.466667vw;
}
.m-annotationall .m-annotation-top .m-annotation-banner .m-annotation-baner-txt {
  text-align: center;
  top: 60.933333vw;
}
.m-annotationall .m-annotation-top .m-annotation-banner .m-annotation-baner-toptagall {
  position: absolute;
  width: 100%;
  height: 48vw;
  top: 94.933333vw;
}
.m-annotationall .m-annotation-top .m-annotation-banner .m-annotation-baner-toptagall .m-annotation-baner-toptag {
  position: absolute;
  display: inline-block;
  width: 33.733333vw;
  height: 18vw;
  font-size: 4vw;
}
.m-annotationall .m-annotation-top .m-annotation-banner .m-annotation-baner-toptag:nth-child(1) {
  top: 29.733333vw;
  left: 0px;
  background: url(../../../../assets/tupianbiaozhu@3x.png) no-repeat;
  background-size: 100% 100%;
}
.m-annotationall .m-annotation-top .m-annotation-banner .m-annotation-baner-toptag:nth-child(2) {
  top: 4vw;
  left: 15.2vw;
  background: url(../../../../assets/yinpinbiaozhu@3x.png) no-repeat;
  background-size: 100% 100%;
}
.m-annotationall .m-annotation-top .m-annotation-banner .m-annotation-baner-toptag:nth-child(3) {
  top: 20.8vw;
  left: 33.333333vw;
  background: url(../../../../assets/wenbenbiaozhu@3x.png) no-repeat;
  background-size: 100% 100%;
}
.m-annotationall .m-annotation-top .m-annotation-banner .m-annotation-baner-toptag:nth-child(4) {
  top: 0px;
  left: 50.666667vw;
  background: url(../../../../assets/shipinbiaozhu@3x.png) no-repeat;
  background-size: 100% 100%;
}
.m-annotationall .m-annotation-top .m-annotation-banner .m-annotation-baner-toptag:nth-child(5) {
  top: 28.266667vw;
  left: 66.266667vw;
  background: url(../../../../assets/zidongjiashi@3x.png) no-repeat;
  background-size: 100% 100%;
}
.m-annotationall .m-annotation-top .m-annotation-banner1 .m-annotation-baner-tag .m-annotation-baner-intag {
  padding: 0px 3.466667vw;
  margin-left: 2.666667vw;
}
.m-annotationall .m-annotation-top .m-annotation-banner2 .m-annotation-baner-tag .m-annotation-baner-intag {
  padding: 0px 3.333333vw;
  margin-left: 9.333333vw;
}
.m-annotationall .m-annotation-top .m-annotation-banner3 .m-annotation-baner-tag .m-annotation-baner-intag {
  padding: 0px 2vw;
  margin: 1.333333vw 2.4vw;
}
.m-annotationall .m-annotation-top .m-annotation-banner3 .m-annotation-baner-txt {
  top: 53.333333vw;
}
.m-annotationall .m-annotation-top .m-annotation-banner4 .m-annotation-baner-tag .m-annotation-baner-intag {
  padding: 0px 3.333333vw;
  margin-left: 4vw;
}
.m-annotationall .m-annotation-top .m-annotation-banner5 .m-annotation-baner-tag .m-annotation-baner-intag {
  padding: 0px 2vw;
  margin: 1.333333vw 1.6vw;
}
.m-annotationall .m-annotation-top .m-annotation-banner5 .m-annotation-baner-txt {
  top: 53.466667vw;
}
