.mobile-academy {
  height: auto;
  width: 100%;
}
.mobile-academy .research {
  height: 100vh;
  width: 100%;
  background: #132233;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}
.mobile-academy .research img {
  position: absolute;
  width: 100%;
  height: 100%;
}
.mobile-academy .research .research-title {
  font-size: 7.333333vw;
  margin-bottom: 8.533333vw;
}
.mobile-academy .research .research-subtitle {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 3.733333vw;
  line-height: 8vw;
}
.mobile-academy .research .research-subtitle .bigFootSizeSpan {
  font-size: xx-large;
}
.mobile-academy .field {
  background: #132233 url("@/assets/academybg2.png") no-repeat 100% 100%;
}
.mobile-academy .ability {
  width: 100%;
  background: #132233 url("@/assets/academybg3.png") no-repeat 100% 100%;
  padding: 0 4vw;
  padding-bottom: 13.333333vw;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.mobile-academy .ability .ability-title {
  font-size: 4.8vw;
  margin-top: 15.333333vw;
  margin-bottom: 8vw;
}
.mobile-academy .ability .ability-content {
  width: 100%;
  padding: 6.4vw 6.666667vw;
  background: #1E2A51;
  opacity: 0.8;
}
.mobile-academy .ability .ability-content span {
  display: block;
  text-indent: 2em;
  color: #C4C6CC;
  font-size: 3.2vw;
  line-height: 6vw;
}
.mobile-academy .mechanism {
  width: 100%;
  background: #132233 url("@/assets/academybg4.png") no-repeat 100% 100%;
  padding: 0 8vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.mobile-academy .mechanism .mechanism-title {
  margin-top: 31.733333vw;
  margin-bottom: 21.066667vw;
  font-size: 4.8vw;
  text-align: center;
}
.mobile-academy .mechanism .mechanismImg {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 39.466667vw;
}
.mobile-academy .mechanism .mechanismImg .mechanismImg-top,
.mobile-academy .mechanism .mechanismImg .mechanismImg-bottom {
  display: flex;
  justify-content: space-between;
}
.mobile-academy .mechanism .mechanismImg .mechanismImg-top {
  margin-bottom: 8vw;
}
.mobile-academy .mechanism .mechanismImg .mechanismImg-item {
  width: 39.733333vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.mobile-academy .mechanism .mechanismImg .mechanismImg-item img {
  width: 20vw;
  height: 20vw;
  margin-bottom: 3.2vw;
}
.mobile-academy .mechanism .mechanismImg .mechanismImg-item div {
  font-size: 3.2vw;
  text-align: center;
}
