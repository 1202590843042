.mobile-menu {
  display: flex;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  padding-top: 14.666667vw;
  background: #0C294F;
  z-index: 100;
}
.mobile-menu .mobile-menu-left {
  width: 37.066667vw;
  height: 100%;
  padding: 10.4vw 7.466667vw;
  border-right: 0.266667vw solid rgba(255, 255, 255, 0.5);
}
.mobile-menu .mobile-menu-left a {
  display: block;
  font-size: 3.733333vw;
  color: #FFFFFF;
  margin-bottom: 6.666667vw;
}
.mobile-menu .mobile-menu-right {
  flex: 1 1;
}
.mobile-menu .mobile-menu-right .menu-right-title {
  height: 12.8vw;
  line-height: 12.8vw;
  padding: 0 4vw;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  font-size: 4.266667vw;
}
.mobile-menu .mobile-menu-right .menu-right-subtitle {
  padding: 6.666667vw 9.333333vw;
}
.mobile-menu .mobile-menu-right .menu-right-subtitle a {
  display: block;
  font-size: 3.733333vw;
  color: #FFFFFF;
  margin-bottom: 6.666667vw;
}
