@-webkit-keyframes mybox {
  form {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  to {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }
}
@keyframes mybox {
  form {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  to {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }
}
.saleengine {
  overflow: hidden;
}
.saleengine .top {
  position: relative;
}
.saleengine .top img {
  width: 100%;
}
.saleengine .top .big-name {
  -webkit-animation: mybox 3s forwards;
  animation: mybox 3s forwards;
}
.saleengine .top .text {
  position: absolute;
  top: 21.875vw;
  left: 34.791667vw;
  text-align: center;
}
.saleengine .top .text h2 {
  font-size: 3.645833vw;
  font-weight: 400;
  color: #ffffff;
}
.saleengine .top .text div {
  font-size: 1.302083vw;
  margin-top: -0.15625vw;
}
.saleengine .center {
  position: relative;
  margin-top: -1px;
}
.saleengine .center img {
  width: 100%;
}
.saleengine .center .saleengine-list .saleengine-item-1 {
  position: absolute;
  top: 8.489583vw;
  left: 5.46875vw;
  width: 28.59375vw;
}
.saleengine .center .saleengine-list .saleengine-item-2 {
  position: absolute;
  top: 26.302083vw;
  left: 20.833333vw;
  width: 20.416667vw;
}
.saleengine .center .saleengine-list .saleengine-item-2 .text {
  text-align: center;
  position: absolute;
  top: 6.25vw;
  left: 4.6875vw;
}
.saleengine .center .saleengine-list .saleengine-item-2 .text h4 {
  color: #ffffff;
  font-size: 1.25vw;
  font-weight: bold;
}
.saleengine .center .saleengine-list .saleengine-item-2 .text p {
  margin-bottom: 0;
  font-size: 0.729167vw;
}
.saleengine .center .saleengine-list .saleengine-item-3 {
  position: absolute;
  top: 15.625vw;
  left: 31.354167vw;
  width: 20.416667vw;
}
.saleengine .center .saleengine-list .saleengine-item-3 .text {
  text-align: center;
  position: absolute;
  top: 6.25vw;
  left: 5.729167vw;
}
.saleengine .center .saleengine-list .saleengine-item-3 .text h4 {
  color: #ffffff;
  font-size: 1.25vw;
  font-weight: bold;
  margin-bottom: 1.40625vw;
}
.saleengine .center .saleengine-list .saleengine-item-3 .text p {
  margin-bottom: 0;
  font-size: 0.729167vw;
}
.saleengine .center .saleengine-list .saleengine-item-4 {
  position: absolute;
  top: 26.302083vw;
  left: 42.083333vw;
  width: 20.416667vw;
}
.saleengine .center .saleengine-list .saleengine-item-4 .text {
  text-align: center;
  position: absolute;
  top: 6.25vw;
  left: 3.229167vw;
}
.saleengine .center .saleengine-list .saleengine-item-4 .text h4 {
  color: #ffffff;
  font-size: 1.25vw;
  font-weight: bold;
  margin-bottom: 1.302083vw;
}
.saleengine .center .saleengine-list .saleengine-item-4 .text p {
  margin-bottom: 0;
  font-size: 0.729167vw;
}
.saleengine .center .saleengine-list .saleengine-item-5 {
  position: absolute;
  top: 15.625vw;
  right: 25.78125vw;
  width: 20.416667vw;
}
.saleengine .center .saleengine-list .saleengine-item-5 .text {
  text-align: center;
  position: absolute;
  top: 5.208333vw;
  left: 4.427083vw;
}
.saleengine .center .saleengine-list .saleengine-item-5 .text h4 {
  color: #ffffff;
  font-size: 1.25vw;
  font-weight: bold;
}
.saleengine .center .saleengine-list .saleengine-item-5 .text p {
  margin-bottom: 0.364583vw;
  font-size: 0.729167vw;
}
.saleengine .center .saleengine-list .saleengine-item-6 {
  position: absolute;
  top: 26.302083vw;
  right: 15.104167vw;
  width: 20.416667vw;
}
.saleengine .center .saleengine-list .saleengine-item-6 .text {
  text-align: center;
  position: absolute;
  top: 6.25vw;
  left: 8.854167vw;
}
.saleengine .center .saleengine-list .saleengine-item-6 .text h4 {
  color: #ffffff;
  font-size: 1.25vw;
  font-weight: bold;
}
.saleengine .center .saleengine-list .saleengine-item-7 {
  position: absolute;
  top: 15.625vw;
  right: 4.6875vw;
  width: 20.416667vw;
}
.saleengine .buttom {
  position: relative;
}
.saleengine .buttom img {
  width: 100%;
}
.saleengine .buttom .list .item-1 {
  position: absolute;
  top: 10.729167vw;
  left: 10.833333vw;
  width: 26.666667vw;
}
.saleengine .buttom .list .item-1 h3 {
  position: absolute;
  margin-bottom: 0;
  font-size: 1.5625vw;
  color: #ffffff;
  font-weight: 400;
  top: 0.364583vw;
  left: 5.729167vw;
}
.saleengine .buttom .list .item-1 .text {
  position: absolute;
  text-align: end;
  top: 4.427083vw;
}
.saleengine .buttom .list .item-1 .text p {
  margin-bottom: 0.364583vw;
  font-size: 0.833333vw;
}
.saleengine .buttom .list .item-2 {
  position: absolute;
  top: 26.666667vw;
  left: 10.833333vw;
  width: 26.666667vw;
}
.saleengine .buttom .list .item-2 h3 {
  position: absolute;
  margin-bottom: 0;
  font-size: 1.5625vw;
  color: #ffffff;
  font-weight: 400;
  top: 2.395833vw;
  left: 5.729167vw;
}
.saleengine .buttom .list .item-2 .text {
  position: absolute;
  text-align: end;
  top: 6.510417vw;
  left: -1.5625vw;
}
.saleengine .buttom .list .item-2 .text p {
  margin-bottom: 0.364583vw;
  font-size: 0.833333vw;
}
.saleengine .buttom .list .item-3 {
  position: absolute;
  top: 9.21875vw;
  right: 12.34375vw;
  width: 25.15625vw;
}
.saleengine .buttom .list .item-3 h3 {
  position: absolute;
  margin-bottom: 0;
  font-size: 1.5625vw;
  color: #ffffff;
  font-weight: 400;
  top: 0.364583vw;
  left: 9.375vw;
}
.saleengine .buttom .list .item-3 .text {
  position: absolute;
  top: 4.427083vw;
  left: 8.333333vw;
  width: 26.041667vw;
}
.saleengine .buttom .list .item-3 .text p {
  margin-bottom: 0.364583vw;
  font-size: 0.833333vw;
}
.saleengine .buttom .list .item-4 {
  position: absolute;
  top: 22.760417vw;
  right: 12.34375vw;
  width: 25.15625vw;
}
.saleengine .buttom .list .item-4 h3 {
  position: absolute;
  margin-bottom: 0;
  font-size: 1.5625vw;
  color: #ffffff;
  font-weight: 400;
  top: 0.364583vw;
  left: 10.15625vw;
}
.saleengine .buttom .list .item-4 .text {
  position: absolute;
  top: 4.427083vw;
  left: 8.333333vw;
  width: 26.041667vw;
}
.saleengine .buttom .list .item-4 .text p {
  margin-bottom: 0.364583vw;
  font-size: 0.833333vw;
}
.saleengine .buttom .list .item-5 {
  position: absolute;
  top: 33.802083vw;
  right: 12.34375vw;
  width: 25.15625vw;
}
.saleengine .buttom .list .item-5 h3 {
  position: absolute;
  margin-bottom: 0;
  font-size: 1.5625vw;
  color: #ffffff;
  font-weight: 400;
  top: 2.864583vw;
  left: 10.9375vw;
}
.saleengine .buttom .list .item-5 .text {
  position: absolute;
  top: 6.770833vw;
  left: 8.333333vw;
  width: 26.041667vw;
}
.saleengine .buttom .list .item-5 .text p {
  margin-bottom: 0.364583vw;
  font-size: 0.833333vw;
}
