.commen-header {
  width: 100%;
  position: fixed;
  height: 3.645833vw;
  padding: 0 7.8125vw;
  z-index: 1;
  border-bottom: 0.104167vw solid rgba(217, 130, 52, 0.5);
  display: flex;
  justify-content: space-between;
}
.commen-header .header-logo {
  height: 100%;
  display: flex;
  align-items: center;
}
.commen-header .header-logo img {
  width: 8.333333vw;
  height: 1.5625vw;
}
.commen-header .header-logo span {
  font-size: 1.25vw;
  margin-left: 1.5625vw;
}
.commen-header .header-nav {
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  direction: rtl;
}
.commen-header .header-nav .ant-menu-submenu {
  padding: 0 1.822917vw !important;
}
.commen-header .ant-menu-horizontal {
  border: none;
}
.opacity {
  background-color: rgba(0, 0, 0);
}
.ant-menu-submenu > .ant-menu {
  background-color: rgba(7, 44, 87) !important;
  opacity: 0.8 !important;
  box-shadow: 0px 0px 0.833333vw 0px rgba(15, 22, 35, 0.3);
  color: #fff;
  text-align: center;
  font-size: 0.729167vw;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #021F43 !important;
  color: #D98234;
  border-bottom: 0px!important;
}
.ant-menu-item-active {
  color: #D98234 !important;
}
.ant-menu {
  font-size: 0.729167vw;
  background: transparent!important;
}
.ant-menu .ant-menu-item::after {
  content: none!important;
}
.ant-menu .ant-menu-submenu::after {
  content: none!important;
}
.ant-menu-item a,
.ant-menu-submenu-title a {
  color: #fff !important;
  font-size: 0.833333vw;
  padding: 0!important;
}
.ant-menu-item a:hover,
.ant-menu-item-selected a,
.ant-menu-submenu-selected a {
  color: #D98234 !important;
}
