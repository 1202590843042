.mCarAI {
  overflow: hidden;
}
.mCarAI .top {
  position: relative;
  /* .big-name {
      animation: mybox 3s forwards;
    } */
}
.mCarAI .top img {
  width: 100%;
}
.mCarAI .top .text {
  position: absolute;
  top: 83.2vw;
  left: 21.333333vw;
  text-align: center;
}
.mCarAI .top .text h2 {
  font-size: 7.333333vw;
  font-family: SourceHanSansCN-Normal;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 8vw;
  text-align: center;
}
.mCarAI .top .text div {
  width: 57.6vw;
  height: 35.6vw;
  font-size: 3.733333vw;
  font-family: SourceHanSansCN-Normal;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 8vw;
}
.mCarAI .buttom {
  position: relative;
}
.mCarAI .buttom img {
  width: 100%;
}
.mCarAI .buttom .item-1,
.mCarAI .buttom .item-2,
.mCarAI .buttom .item-3 {
  position: absolute;
  width: 100%;
  text-align: center;
}
.mCarAI .buttom .list-itemP {
  font-size: 4.8vw;
  font-family: SourceHanSansCN-Normal;
  font-weight: 400;
  color: #FFFFFF;
}
.mCarAI .buttom .content {
  width: 100%;
  padding-left: 4vw;
  padding-right: 4vw;
  margin-top: 5.333333vw;
  display: flex;
}
.mCarAI .buttom .content1,
.mCarAI .buttom .content2,
.mCarAI .buttom .content3 {
  flex: 1 1;
  width: 29.333333vw;
  height: 61.333333vw;
  text-align: center;
  border-radius: 1.333333vw;
  opacity: 0.9;
}
.mCarAI .buttom .content1-top {
  width: 100%;
  height: 50.666667vw;
  border-radius: 1.333333vw;
  opacity: 0.9;
}
.mCarAI .buttom .content1-bot {
  width: 26.666667vw;
  height: 8vw;
  margin-left: 1.333333vw;
  font-size: 3.2vw;
  font-family: SourceHanSansCN-Normal;
  font-weight: 400;
  color: #FFFFFF;
}
.mCarAI .buttom .list .item-1 {
  top: 15.333333vw;
}
.mCarAI .buttom .list .item-1 .content1 .content1-top {
  background: url(../../../../assets/1111.png), no-repeat;
  background-size: 100% 100%;
}
.mCarAI .buttom .list .item-1 .content2 {
  margin: 0px 1.866667vw;
}
.mCarAI .buttom .list .item-1 .content2 .content1-top {
  background: url(../../../../assets/2222.png), no-repeat;
  background-size: 100% 100%;
}
.mCarAI .buttom .list .item-1 .content3 .content1-top {
  background: url(../../../../assets/3333.png), no-repeat;
  background-size: 100% 100%;
}
.mCarAI .buttom .list .item-2 {
  top: 97.6vw;
}
.mCarAI .buttom .list .item-2 .content1 .content1-top {
  background: url(../../../../assets/4444.png), no-repeat;
  background-size: 100% 100%;
}
.mCarAI .buttom .list .item-2 .content2 {
  margin: 0px 1.866667vw;
}
.mCarAI .buttom .list .item-2 .content2 .content1-top {
  background: url(../../../../assets/5555.png), no-repeat;
  background-size: 100% 100%;
}
.mCarAI .buttom .list .item-2 .content3 .content1-top {
  background: url(../../../../assets/6666.png), no-repeat;
  background-size: 100% 100%;
}
.mCarAI .buttom .list .item-3 {
  top: 186.666667vw;
}
.mCarAI .buttom .list .item-3 .content1 .content1-top {
  background: url(../../../../assets/7777.png), no-repeat;
  background-size: 100% 100%;
}
.mCarAI .buttom .list .item-3 .content2 {
  margin: 0px 1.866667vw;
}
.mCarAI .buttom .list .item-3 .content2 .content1-top {
  background: url(../../../../assets/8888.png), no-repeat;
  background-size: 100% 100%;
}
.mCarAI .buttom .list .item-3 .content3 .content1-top {
  background: url(../../../../assets/9999.png), no-repeat;
  background-size: 100% 100%;
}
@-webkit-keyframes mybox {
  form {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  to {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }
}
@keyframes mybox {
  form {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  to {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }
}
