.mobile-header {
  width: 100%;
  height: 14.4vw;
  border-bottom: 0.266667vw solid rgba(217, 130, 52, 0.5);
  padding: 0 4vw;
  position: fixed;
  display: flex;
  align-items: center;
}
.mobile-header img {
  width: 26.133333vw;
  height: 4.8vw;
}
.mobile-header span {
  line-height: 4.8vw;
  font-size: 3.733333vw;
  height: 4.8vw;
  color: #fff;
  margin-left: 4.8vw;
}
.mobile-header .more-icon {
  position: absolute;
  right: 4.266667vw;
  width: 4.8vw;
  height: 4.8vw;
}
